import { useEffect, useRef, useState } from 'react'
import { formatSectionData } from '../utils/helpers'
import { addLoginChangedListener } from '../jweb/listeners'
import {
  HPX_ACTIONS_TYPE,
  HPX_BANNER_TYPE,
  HPX_NEXTSTEPS_TYPE,
  HPX_PROMOTIONAL_TYPE,
  SHOW_PAGE_EVENT_NAME
} from '../utils/constants'
import {
  getCurrentContext,
  getFeatureList,
  setUserInfo
} from '../utils/persistData'
import { trackPageViewSimpleUIEvent } from '../utils/simpleUIAnalytics'
import { fetchSectionInfo } from '../utils/request'
import { publishEvent } from '../jweb/plugins/eventServiceLite'

const emptySection = {}

const getSectionsInfo = async (sectionsToDisplay, setterEnum) => {
  sectionsToDisplay.map((section) => {
    let context = getCurrentContext()
    if (section === HPX_PROMOTIONAL_TYPE || section === HPX_ACTIONS_TYPE) {
      const featureList = getFeatureList()
      context = { ...context, supportedPCControls: featureList }
    }
    fetchSectionInfo(section, { ...context }).then((result) => {
      const setterFunc = setterEnum[section]
      const formattedResult = formatSectionData(result.data, section)
      setterFunc(formattedResult)
    })
  })
}

export const useInit = (sectionsToDisplay, isAppContextFetched) => {
  const [bannerInfo, setBannerInfo] = useState(emptySection)
  const [actionsInfo, setActionsInfo] = useState(emptySection)
  const [promotionsInfo, setPromotionsInfo] = useState(emptySection)
  const [nextStepsInfo, setNextStepsInfo] = useState(emptySection)
  const [pageReady, setPageReady] = useState(false)
  const setterEnum = useRef({
    [HPX_BANNER_TYPE]: setBannerInfo,
    [HPX_ACTIONS_TYPE]: setActionsInfo,
    [HPX_NEXTSTEPS_TYPE]: setNextStepsInfo,
    [HPX_PROMOTIONAL_TYPE]: setPromotionsInfo
  })

  const updateSectionsOnAuthChange = (userDetails) => {
    // console.log('login changed', JSON.stringify(userDetails))
    setUserInfo(userDetails)
    getSectionsInfo(sectionsToDisplay, setterEnum.current)
  }

  // Get app context and load sections on  initial load
  useEffect(() => {
    const onLoad = async () => {
      if (isAppContextFetched) {
        getSectionsInfo(sectionsToDisplay, setterEnum.current)
      }
    }
    onLoad()
  }, [isAppContextFetched])

  // Subscribe to login change events to refetch sections
  useEffect(() => {
    return addLoginChangedListener(updateSectionsOnAuthChange)
  }, [])

  // Publish show/hide event on section load
  useEffect(() => {
    const publishShowEvent = (type, show) => {
      publishEvent(SHOW_PAGE_EVENT_NAME, {
        type: type,
        show: show
      })
      trackPageViewSimpleUIEvent(type)
    }
    if (sectionsToDisplay?.length > 1) {
      // whole page displayed
      if (
        ((bannerInfo && Object.keys(bannerInfo).length) ||
          actionsInfo?.engagements?.length ||
          promotionsInfo?.engagements?.length ||
          nextStepsInfo?.engagements?.length) &&
        !pageReady
      ) {
        setPageReady(true)
        publishEvent(SHOW_PAGE_EVENT_NAME)
        trackPageViewSimpleUIEvent()
      }
    } else {
      //loading separate sections
      switch (sectionsToDisplay[0]) {
        case HPX_BANNER_TYPE: {
          if (bannerInfo && Object.keys(bannerInfo).length) {
            if (!pageReady) publishShowEvent(HPX_BANNER_TYPE, true)
            setPageReady(true)
          } else {
            publishShowEvent(HPX_BANNER_TYPE, false)
          }
          break
        }
        case HPX_ACTIONS_TYPE: {
          if (actionsInfo?.engagements) {
            if (actionsInfo?.engagements?.length) {
              if (!pageReady) publishShowEvent(HPX_ACTIONS_TYPE, true)
              setPageReady(true)
            } else {
              publishShowEvent(HPX_ACTIONS_TYPE, false)
            }
          }
          break
        }
        case HPX_NEXTSTEPS_TYPE: {
          if (nextStepsInfo?.engagements) {
            if (nextStepsInfo?.engagements?.length) {
              if (!pageReady) publishShowEvent(HPX_NEXTSTEPS_TYPE, true)
              setPageReady(true)
            } else {
              publishShowEvent(HPX_NEXTSTEPS_TYPE, false)
            }
          }
          break
        }
        case HPX_PROMOTIONAL_TYPE: {
          if (promotionsInfo?.engagements) {
            // Fetch call returned section info
            if (promotionsInfo?.engagements?.length) {
              // There are tiles in the section, send show true event
              if (!pageReady) {
                publishShowEvent(HPX_PROMOTIONAL_TYPE, true)
                console.log(`Show event posted at: ${performance.now()}`)
              }
              setPageReady(true)
            } else {
              // No tiles in the section, send show false event
              publishShowEvent(HPX_PROMOTIONAL_TYPE, false)
            }
          }
          break
        }
      }
    }
  }, [
    bannerInfo,
    actionsInfo,
    promotionsInfo,
    nextStepsInfo,
    sectionsToDisplay
  ])

  return {
    bannerInfo,
    actionsInfo,
    promotionsInfo,
    nextStepsInfo,
    pageReady
  }
}
