import { jWebReady } from '@jarvis/jweb-core'
/**
 * Fetch value of a key from value store.
 * @param {string} key
 * @return {Promise} value of key
 */
export const getValueFromValueStore = async (key) => {
  try {
    const jweb = await jWebReady

    if (jweb && jweb.isNative) {
      const { ValueStore } = jweb.Plugins
      // console.log(`get value from valuestore for key ${key}`)
      return (await ValueStore.get({ key: key })).result
    }
    return null
  } catch (error) {
    console.error(`Value store not available`)
    return null
  }
}
