import styled from 'styled-components'
import IconLightBulb from '@veneer/core/dist/scripts/icons/icon_lightbulb'
import { ANDROID, IOS, MAC } from '../../jweb/constants'

const textDirection = (dir: string) => (dir === 'rtl' ? 'right' : 'left')

export const PromotionCard = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  white-space: normal;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: 0.2rem;
`
export const ImageContainer = styled.div<{ src: string }>`
  width: 100%;
  height: ${({ theme: { mobileHost } }) => (mobileHost ? '19.4rem' : '22rem')};
  border-radius: 0.8rem;
  position: relative;
  overflow: hidden;

  img {
    background-color: #d9d9d9;
    min-width: 100%;
  }
  // background-color: #d9d9d9;
  // background-image: ${({ src }) => src};
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
`

export const PromotionTag = styled.div`
  position: absolute;
  ${({ theme: { dir } }) =>
    dir === 'rtl' ? 'left: 0.77rem' : 'right: 0.77rem'};
  top: 0.8rem;
  height: 2.4rem;
  width: 2.4rem;
  background-color: #ffffff;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const StyledIconLightBulb = styled(IconLightBulb)`
  && {
    fill: #027aae;
    height: 1.6rem;
    width: 1.6rem;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: ${({ theme: { dir } }) => textDirection(dir)};
  padding-top: 0.8rem;
`

export const StyledTitle = styled.div`
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2rem;
  color: ${({ theme: { styles } }) => styles.colorTileTitle};
`

export const StyledDescription = styled.div<{ computedFontSize: number }>`
  color: ${({ theme: { styles } }) => styles.colorTileTitle};
  font-size: 1.2rem;
  line-height: ${({ theme: { platform } }) =>
    [ANDROID, IOS, MAC].includes(platform) ? 'auto' : '1.6rem'};
  font-weight: normal;
  -webkit-line-clamp: ${({ computedFontSize }) =>
    computedFontSize > 16 ? 1 : 3};
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin-top: 0.4rem;
`
