import { registerServiceInstanceClosedListener } from '../listeners'
import { jWebReady } from '@jarvis/jweb-core'

/**
 * Call Service Routing Plugin to obtain all available services.
 * @returns A list of services or empty array if there is an error or services are not available.
 */
const getAvailableServices = async () => {
  try {
    const jweb = await jWebReady

    if (jweb && jweb.isNative) {
      const { ServiceRouting } = jweb.Plugins
      const listOfServices = await ServiceRouting.getServices()
      if (listOfServices.errorType) {
        console.error(
          `SERVICE ROUTING AVAILABLE SERVICES ERROR: ${listOfServices.errorType} - ${listOfServices.message}`
        )
        return []
      }
      return listOfServices?.services
    }
    console.error(
      'SERVICE ROUTING AVAILABLE SERVICES ERROR: JWeb is not ready for sending events.'
    )
  } catch (error) {
    console.error(`SERVICE ROUTING AVAILABLE SERVICES ERROR: ${error}`)
  }
}

/**
 * Returns the Service through Service Routing Plugin.
 * @param {string} serviceId Service ID
 * @param {string} containerViewType Container View Type from JWeb Plugin (fullScreen | determinedByService | modal)
 * @returns The requested Service if available.  Returns null, if not available.
 */
const getServiceIfAvailable = async (serviceId) => {
  const listOfServices = await getAvailableServices()
  const service = listOfServices.find(
    (servicelist) => servicelist.id === serviceId
  )
  if (service) {
    return service
  }
  return null
}

/**
 * Opens the URL in a full screen JWeb View.
 * @param {string} url The URL being launched
 * @param {boolean=} isBelowNavBar Activates the native app navigation bar (true | false)
 * @param {boolean=} useExternalBrowser The URL being launched externally (true | false)
 * @param {string=} containerViewType Container View Type from JWeb Plugin (fullScreen | determinedByService | modal)
 * @param {string=} containerCloseBehavior Container Close Behavior from JWeb Plugin (returnToCaller)
 */
export const launchURL = async (
  url,
  isBelowNavBar = false,
  useExternalBrowser = false,
  containerViewType = 'fullScreen',
  containerCloseBehavior = 'returnToCaller',
  eventPostCallback
) => {
  try {
    const OPEN_URL_SERVICE = 'openUrl'
    const jweb = await jWebReady

    if (jweb && jweb.isNative) {
      const { ServiceRouting } = jweb.Plugins
      const getURLService = await getServiceIfAvailable(OPEN_URL_SERVICE)

      if (getURLService) {
        const serviceOptions = {
          serviceId: OPEN_URL_SERVICE,
          containerOptions: {
            containerViewOptions: {
              containerViewType,
              belowNavBar: isBelowNavBar
            },
            containerCloseBehaviorOptions: {
              closeBehavior: containerCloseBehavior
            }
          },
          serviceOptions: {
            url,
            useExternalBrowser
          }
        }

        const serviceLaunch = await ServiceRouting.launchService(serviceOptions)
        console.log('Service Routing Launched')

        if (process.env.NODE_ENV !== 'production') {
          console.log(
            `Service Routing: ${JSON.stringify(serviceOptions, null, 2)}`
          )
          console.log(
            `Service Routing Response: ${JSON.stringify(
              serviceLaunch,
              null,
              2
            )}`
          )
        }

        if (serviceLaunch.errorType) {
          console.error(
            `LAUNCH URL ERROR: ${serviceLaunch.errorType} - ${serviceLaunch.message}`
          )
        } else {
          registerServiceInstanceClosedListener(serviceLaunch.eventPublisherId)
          eventPostCallback()
        }
      } else {
        console.error('LAUNCH URL ERROR: openUrl service not found.')
      }
    } else {
      window.location.assign(`${url}`)
    }
  } catch (error) {
    console.error(`LAUNCH URL ERROR: ${error}`)
  }
}

export const closeServiceInstance = async (closeOptions = {}) => {
  const jweb = await jWebReady

  if (jweb && jweb.isNative) {
    const { ServiceRouting } = jweb.Plugins
    ServiceRouting.closeServiceInstance(closeOptions)
  }
}

/**
 * Send an event to Native Application.  Native Application must have a listener for the events sent.
 * @param {string} eventName Name and unique identifier for the event
 * @param {JSON} eventData The data to send to the Native Application
 */
export const sendEvent = async (eventName, eventData) => {
  try {
    const jweb = await jWebReady

    if (jweb && jweb.isNative) {
      const { Eventing } = jweb.Plugins
      const event = {
        name: eventName,
        data: eventData
      }

      await Eventing.dispatchEvent(event)
    } else {
      console.info(eventName, eventData)
    }
  } catch (error) {
    console.error(`SEND EVENT ERROR: ${error}`)
  }
}
