import { Action } from '@jarvis/jweb-core'
import { SIMPLE_UI_SCHEMA_VERSION } from '../jweb/constants'
import {
  sendSimpleUiEvent,
  sendSimpleUiEventList
} from '../jweb/plugins/dataCollection'

import { ANALYTICS } from './constants'

export const trackPageViewSimpleUIEvent = (
  controlType,
  controlName,
  controlDetail
) => {
  sendSimpleUiEvent({
    version: SIMPLE_UI_SCHEMA_VERSION,
    action: Action.screenDisplayed,
    screenPath: ANALYTICS.SCREEN_PATH,
    screenName: ANALYTICS.SCREEN_NAME,
    activity: controlType
      ? ANALYTICS[controlType]?.ACTIVITY
      : ANALYTICS.ACTIVITY,
    controlName,
    controlDetail
  })
}

export const trackTileViewSimpleUIEvent = (tiles, controlType) => {
  const { ACTIVITY } = ANALYTICS[controlType] || {}
  if (!Array.isArray(tiles)) {
    tiles = new Array(tiles)
  }
  const simpleUIEvents = []
  tiles.forEach((tile) => {
    simpleUIEvents.push({
      version: SIMPLE_UI_SCHEMA_VERSION,
      action: Action.controlDisplayed,
      screenPath: ANALYTICS.SCREEN_PATH_CAROUSEL,
      screenName: ANALYTICS.SCREEN_NAME,
      controlName: tile.campaignName,
      controlDetail: tile.friendlyName || tile.campaignName,
      screenMode: 'Default',
      activity: ACTIVITY,
      actionAuxParams: `position=${tile.position} campaignType=${tile.campaignType} id=${tile.id} taxonomyName=${tile.taxonomyName}`
    })
  })
  sendSimpleUiEventList(simpleUIEvents)
}

export const trackTileClickedSimpleUIEvent = (
  controlName,
  controlDetail,
  campaignType,
  tileType,
  index,
  taxonomyName
) => {
  const { ACTIVITY } = ANALYTICS[tileType] || {}
  sendSimpleUiEvent({
    version: SIMPLE_UI_SCHEMA_VERSION,
    action: Action.controlTileClicked,
    screenPath: ANALYTICS.SCREEN_PATH_CAROUSEL,
    screenName: ANALYTICS.SCREEN_NAME,
    controlName,
    controlDetail,
    activity: ACTIVITY,
    actionAuxParams: `position=${
      index + 1
    } campaignType=${campaignType} taxonomyName=${taxonomyName}`
  })
}

export const trackScrollClickedSimpleUiEvent = (controlName, tileType) => {
  const { ACTIVITY } = ANALYTICS[tileType] || {}
  sendSimpleUiEvent({
    version: SIMPLE_UI_SCHEMA_VERSION,
    action: Action.controlButtonClicked,
    screenPath: ANALYTICS.SCREEN_PATH_CAROUSEL,
    screenName: ANALYTICS.SCREEN_NAME,
    controlName,
    screenMode: 'Default',
    activity: ACTIVITY
  })
}

export const trackTileDismissalSimpleUIEvent = (
  controlName,
  controlDetail,
  campaignType,
  tileType,
  taxonomyName
) => {
  const { ACTIVITY } = ANALYTICS[tileType] || {}
  sendSimpleUiEvent({
    version: SIMPLE_UI_SCHEMA_VERSION,
    action: Action.controlHyperLinkClicked,
    screenPath: ANALYTICS.SCREEN_PATH,
    screenName: ANALYTICS.SCREEN_NAME,
    controlName,
    activity: ACTIVITY,
    screenMode: 'Default',
    controlDetail,
    actionAuxParams: `campaignType=${campaignType} taxonomyName=${taxonomyName}`
  })
}

export const trackTileSnoozeSimpleUIEvent = (
  controlName,
  controlDetail,
  campaignType,
  tileType,
  tile,
  taxonomyName
) => {
  const { ACTIVITY } = ANALYTICS[tileType] || {}
  sendSimpleUiEvent({
    version: SIMPLE_UI_SCHEMA_VERSION,
    action: Action.controlHyperLinkClicked,
    screenPath: ANALYTICS.SCREEN_PATH,
    screenName: ANALYTICS.SCREEN_NAME,
    controlName,
    activity: ACTIVITY,
    screenMode: 'Default',
    controlDetail,
    actionAuxParams: `position=${tile.position} campaignType=${campaignType} taxonomyName=${taxonomyName}`
  })
}

export const trackButtonClickSimpleUIEvent = ({
  controlName,
  controlDetail,
  controlType
}) => {
  const { ACTIVITY } = ANALYTICS[controlType] || {}
  sendSimpleUiEvent({
    version: SIMPLE_UI_SCHEMA_VERSION,
    action: Action.controlButtonClicked,
    screenPath: ANALYTICS.SCREEN_PATH,
    screenName: ANALYTICS.SCREEN_NAME,
    controlName,
    controlDetail,
    activity: ACTIVITY
  })
}

export const trackAccordionClickSimpleUIEvent = ({
  controlName,
  isExpanded,
  controlType
}) => {
  const { ACTIVITY } = ANALYTICS[controlType] || {}
  sendSimpleUiEvent({
    version: SIMPLE_UI_SCHEMA_VERSION,
    action: !isExpanded
      ? Action.controlAccordianExpanded
      : Action.controlAccordianCollapsed,
    screenPath: ANALYTICS.SCREEN_PATH,
    screenName: ANALYTICS.SCREEN_NAME,
    controlName,
    activity: ACTIVITY
  })
}

export const trackLinkClickSimpleUIEvent = ({
  controlName,
  controlDetail,
  controlType
}) => {
  const { ACTIVITY } = ANALYTICS[controlType] || {}
  sendSimpleUiEvent({
    version: SIMPLE_UI_SCHEMA_VERSION,
    action: Action.controlHyperLinkClicked,
    screenPath: ANALYTICS.SCREEN_PATH,
    screenName: ANALYTICS.SCREEN_NAME,
    controlName,
    controlDetail,
    activity: ACTIVITY,
    screenMode: 'Default'
  })
}
