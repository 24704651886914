export const BASE_URL = 'https://cec.hpsmartpie.com/1/api'

export const RTL_LOCALES = ['ar', 'he']

export const DEFAULT_TEXT_DIRECTION = 'ltr'
export const DEFAULT_THEME_MODE = 'light'
export const DEFAULT_PLATFORM = 'web'
export const DEFAULT_MOBILEHOST = false

export const LIGHT_THEME_CONST = 'light'
export const DARK_THEME_CONST = 'dark'
export const HIGH_CONTRAST_THEME_CONST = 'highcontrast'

export const HPX_BANNER_TYPE = 'hpxwelcomebanner'
export const HPX_ACTIONS_TYPE = 'hpxactiontile'
export const HPX_NEXTSTEPS_TYPE = 'hpxnextstepstile'
export const HPX_PROMOTIONAL_TYPE = 'hpxpromotionalcectile'
export const HPX_INTERSTITIAL_TYPE = 'hpxinterstitial'
export const TILE_DISMISS = 'TileDismiss'
export const TILE_SNOOZE = 'TileSnooze'

export const DISMISS = 'Dismiss'
export const SNOOZE = 'Snooze'

export const VALUE_STORE_FEATURES_KEY =
  '@settings-local/@hp-af/feature-switch/features'
export const VALUE_STORE_ANALYTICS_UUID =
  '@hp-af/analytics/application-instance-id'
export const VALUE_STORE_DEVICEINFO_UUID = '@hp-af/device-info/UUID'

export const featureToDeviceControlEnum = {
  'pcaudio-core': 'audio',
  'pcdisplay-core': 'display',
  'pcconnect-core': '5g',
  'smartcam-core': 'video',
  'pencontrol-core': 'pencontrol',
  'rgbkeyboard-core': 'rgbKeyboard',
  'progkey-core': 'programmableKey'
}

export const carouselDirectionEnum = {
  ltr: 1,
  rtl: -1
}

export const ANALYTICS = {
  SCREEN_NAME: 'Home',
  SCREEN_PATH: '/ReactHpxWelcomepage/',
  ACTIVITY: 'WelcomePage-v01',
  SCREEN_PATH_CAROUSEL: '/ReactHpxWelcomepage/Carousel/',
  hpxwelcomebanner: {
    ACTIVITY: 'HpxWelcomeBanner-v01'
  },
  hpxnextstepstile: {
    ACTIVITY: 'HpxNextsteps-v01'
  },
  hpxactiontile: {
    ACTIVITY: 'HpxAction-v01'
  },
  hpxpromotionalcectile: {
    ACTIVITY: 'HpxPromotion-v01'
  },
  hpxinterstitial: {
    ACTIVITY: 'HpxInterstitial-v01'
  }
}

export const SHOW_PAGE_EVENT_NAME = 'showWelcomePage'
export const PAGE_HEIGHT_EVENT = 'welcomePageHeightChange'
export const All_SECTIONS = [
  HPX_BANNER_TYPE,
  HPX_ACTIONS_TYPE,
  HPX_NEXTSTEPS_TYPE,
  HPX_PROMOTIONAL_TYPE
]

export const SMALL_SCREEN_WIDTH_PX = 641
export const MEDIUM_SCREEN_WIDTH_PX = 1008
export const LARGE_SCREEN_WIDTH_PX = 1738

export const Tile_WIDTHS = {
  hpxpromotionalcectile: {
    ios: 240,
    android: 240,
    windows: 260,
    mac: 260,
    web: 240
  }
}

export const MAXIMUM_PROMOTIONAL_TILES_MOBILE = 8
