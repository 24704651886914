import { useState, useEffect } from 'react'
import {
  getCurrentContext,
  getDeviceInfo,
  getMobileHost
} from '../utils/persistData'
import {
  DEFAULT_PLATFORM,
  DEFAULT_MOBILEHOST,
  DEFAULT_TEXT_DIRECTION,
  DEFAULT_THEME_MODE,
  LIGHT_THEME_CONST,
  DARK_THEME_CONST,
  HIGH_CONTRAST_THEME_CONST,
  RTL_LOCALES
} from '../utils/constants'
import { ANDROID, IOS, MAC } from '../jweb/constants'
import { themes } from '../containers/WelcomePage/theme'

const getTheme = (theme) => {
  if (theme) {
    switch (theme) {
      case LIGHT_THEME_CONST:
        return theme
      case DARK_THEME_CONST:
        return theme
      case HIGH_CONTRAST_THEME_CONST:
        return theme
      default:
        return DEFAULT_THEME_MODE
    }
  }
  return DEFAULT_THEME_MODE
}

export const useTheme = (appContextFetched) => {
  const [theme, setTheme] = useState({
    dir: DEFAULT_TEXT_DIRECTION,
    mode: DEFAULT_THEME_MODE,
    styles: themes[DEFAULT_THEME_MODE],
    platform: DEFAULT_PLATFORM,
    mobileHost: DEFAULT_MOBILEHOST
  })

  useEffect(() => {
    if (appContextFetched) {
      let mode = DEFAULT_THEME_MODE
      const { language, property } = getCurrentContext()
      const { appearance, platform } = getDeviceInfo()
      // prettier-ignore
      let mobileHost =
        getMobileHost() || [ANDROID, IOS].includes(`${property}`.split('-')[1])
      if (appearance) {
        //Disabled dark mode on Android, IOS and MAC
        mode = [ANDROID, IOS, MAC].includes(platform)
          ? 'light'
          : getTheme(appearance)
      } else {
        // Workaround for testing theme in browser
        // Sample URL- http://localhost:8080/?mode=dark
        const theme = new URLSearchParams(location.search)
          .get('mode')
          ?.toLowerCase()
        //Disabled dark mode on Android, IOS and MAC
        mode = [ANDROID, IOS, MAC].includes(platform)
          ? 'light'
          : getTheme(theme)
      }
      const textDirection = RTL_LOCALES.includes(language) ? 'rtl' : 'ltr'
      document.body.setAttribute('dir', textDirection)

      if (
        mode !== DEFAULT_THEME_MODE ||
        textDirection !== DEFAULT_TEXT_DIRECTION ||
        platform !== DEFAULT_PLATFORM ||
        mobileHost !== DEFAULT_MOBILEHOST
      ) {
        setTheme({
          dir: textDirection,
          mode: mode,
          styles: themes[mode],
          platform: platform,
          mobileHost: mobileHost
        })
      }
    } else {
      const { property } = getCurrentContext()
      let mobileHost =
        getMobileHost() || [ANDROID, IOS].includes(`${property}`.split('-')[1])
      setTheme((preTheme) => ({
        ...preTheme,
        mobileHost: mobileHost
      }))
    }
  }, [appContextFetched])

  return theme
}
