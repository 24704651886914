/* global JWeb */ // eslint-disable-line
import { jWebReady, ServiceInstanceEvent } from '@jarvis/jweb-core'
import { getUserAuthDetails } from './plugins/auth'
import { AUTH_CHANGE_EVENT, AUTH_PUBLISHER_ID } from './constants'
import pubSub from './pubSub'

/**
 * Adds an event listener for any login changes
 * LoginChange Event Payload {
 *      account: {
 *          accountId: "[Account ID]",
 *          emailAddress: "[Email Address]",
 *          familyName: "[Family/Last Name]"
 *          givenName: "[Given/First Name]"
 *      },
 *      isLoggedIn: "[true | false]"
 * }
 *
 * @param {*} callback Callback with the JWT Value or empty string from the auth information
 * @listens LoginChange Event
 * @returns {Function} Remove Listener Function
 */
export const addLoginChangedListener = async (callback) => {
  try {
    const jweb = await jWebReady
    if (jweb && jweb.isNative) {
      const { EventService } = jweb.Plugins
      const subscriber = await EventService.createSubscriber()
      if (typeof subscriber.errorType !== 'undefined') {
        return
      }
      let subscriptionHandle = await subscriber.subscribe(
        {
          eventName: AUTH_CHANGE_EVENT,
          publisherId: AUTH_PUBLISHER_ID
        },
        async ({ eventData }) => {
          console.log(
            `Received login change event: ${JSON.stringify(eventData)}`
          )
          let jwt = ''
          if (eventData?.currentAccount?.AccountId) {
            jwt = await getUserAuthDetails()
          }
          callback(jwt)
        }
      )
      if (typeof subscriptionHandle.errorType !== 'undefined') {
        return
      }
      return subscriptionHandle.unsubscribe
    }
  } catch (error) {
    console.error(`ADD LOGIN CHANGED LISTENER ERROR: ${error}`)
  }
}

/**
 * Adds an event listener for any printer selection changes
 * Payload {
 *      PrinterUUID: "[Printer UUID]"
 *      PrinterProductNumber: "[Product Number]"
 * }
 * @param {*} callback Callback with the value of the printer UUID in JSON
 * @listens PrinterSelected Event
 * @returns {Function} Remove Listener Function
 */
export const addPrinterSelectedListener = async (callback) => {
  try {
    const jweb = await jWebReady

    if (jweb && jweb.isNative) {
      const { Eventing } = jweb.Plugins
      const printerSelectedListener = await Eventing.addListener(
        'PrinterSelected',
        (printerInfo) => {
          console.log('Printer Selected Information Received')
          if (process.env.NODE_ENV !== 'production') {
            console.log(
              `PrinterSelected: ${JSON.stringify(printerInfo, null, 2)}`
            )
          }
          callback(printerInfo)
        }
      )
      return printerSelectedListener.remove
    }
    window.addEventListener('PrinterSelected', callback)
    return () => window.removeEventListener('PrinterSelected', callback)
  } catch (error) {
    console.error(`ADD PRINTER SELECTED LISTENER ERROR: ${error}`)
  }
}

/**
 * Registers an event listener for any Service Instance State Changes

 * @param {*} publisherId Id of the service instance
 * @listens serviceInstanceStateChanged Event
 * @returns {Function} Remove Listener Function
 */
export const registerServiceInstanceClosedListener = async (publisherId) => {
  try {
    const jweb = await jWebReady

    if (jweb && jweb.isNative) {
      const { EventService } = jweb.Plugins
      const subscriber = await EventService.createSubscriber()
      if (typeof subscriber.errorType !== 'undefined') {
        return
      }

      const serviceInstanceStateChangeListener = await subscriber.subscribe(
        {
          eventName: ServiceInstanceEvent.jarvisEventServiceInstanceClosed,
          publisherId
        },
        ({ eventData: serviceInstance }) => {
          console.log(
            `serviceInstanceStateChanged: ${JSON.stringify(
              {
                state: serviceInstance.state,
                serviceId: serviceInstance.serviceId
              },
              null,
              2
            )}`
          )
          pubSub.publish(
            ServiceInstanceEvent.jarvisEventServiceInstanceClosed,
            serviceInstance
          )
        }
      )
      pubSub.runOnUnsubscribe(
        ServiceInstanceEvent.jarvisEventServiceInstanceClosed,
        serviceInstanceStateChangeListener.unsubscribe
      )
    }
  } catch (error) {
    console.error(
      `REGISTER SERVICE INSTANCE STATE CHANGE LISTENER ERROR: ${error}`
    )
  }
}

/**
 * Adds an event listener for any Service Instance State Changes
 * Payload {
 *      serviceId: "[Service ID]",
 *      state: "[State of Service]"
 * }
 * @param {*} callback Callback with the value of the Service State Data in JSON
 * @listens serviceInstanceStateChanged Event
 * @returns {Function} Remove Listener Function
 */
export const addServiceInstanceClosedListener = (callback) => {
  pubSub.subscribe(
    ServiceInstanceEvent.jarvisEventServiceInstanceClosed,
    callback
  )
  return () =>
    pubSub.unsubscribe(ServiceInstanceEvent.jarvisEventServiceInstanceClosed)
}
