import { jWebReady } from '@jarvis/jweb-core'
import { DATA_COLLECTION_PUBLISHER_ID } from '../constants'

let subscriber

export const createSubscriber = async () => {
  const jweb = await jWebReady

  if (jweb && jweb.isNative) {
    const { EventService } = jweb.Plugins

    try {
      subscriber = await EventService.createSubscriber()

      if (subscriber.errorType !== undefined) {
        console.error('events::createSubscriber: %o', subscriber)
      }
      return subscriber
    } catch (e) {
      console.log('error creating subscriber %o', e)
    }
  }
}

export const createPublisher = async () => {
  const jweb = await jWebReady

  if (jweb && jweb.isNative) {
    const { EventService } = jweb.Plugins

    const options = {
      allowEventingFallback: true
    }

    try {
      return await EventService.createPublisher(
        `${DATA_COLLECTION_PUBLISHER_ID}.${Date.now() + Math.random()}`,
        options
      )
    } catch (e) {
      console.error('Error creating Publisher: %o', e)
    }
  }

  return Promise.resolve({
    publish: () => {
      return Promise.resolve()
    }
  })
}

export const publishEvent = async (eventName, eventData) => {
  console.log('events::publishEvent: %o: %o', eventName, eventData)
  const publisher = await createPublisher()

  // Uncomment to get status of posted event
  // if (!subscriber) {
  //   subscriber = await createSubscriber()
  //   await subscriber.subscribe(
  //     {
  //       eventName: DataCollectionEventNames.cdmEventStatus
  //     },
  //     (e) => console.info(JSON.stringify(e.eventData, null, 2))
  //   )
  // }
  if (publisher && publisher.errorType !== undefined) {
    console.error('Error creating ', publisher)
  }

  if (publisher && publisher.publish) {
    let myIdentifier = 'TelemetryEventHomePage-' + Date.now()
    eventData.trackingIdentifier = myIdentifier
    publisher
      .publish(eventName, eventData)
      .then((result) => {
        if (result !== undefined) {
          console.log('Unable to publish event')
        } else {
          console.log(` ${eventName} event published successfully`)
        }
      })
      .catch((error) => console.error('events::publishEvent error: %o', error))
  }
}
