import React from 'react'
import { useEffect, useState } from 'react'
import { getCurrentContext } from '../../utils/persistData'
import { fetchInterstitial } from '../../utils/request'
import Interstitial from '../../components/Interstitial'
import { formatSectionData } from '../../utils/helpers'
import { HPX_INTERSTITIAL_TYPE } from '../../utils/constants'
import { triggerEvent } from '../../jweb/callToAction'
import { trackPageViewSimpleUIEvent } from '../../utils/simpleUIAnalytics'

const InterstitialScreen = (props) => {
  const { entryUid, contentTypeUid, originName, originType } = props
  const [screenData, setScreenData] = useState()

  useEffect(() => {
    const getScreenData = async () => {
      const context = getCurrentContext()
      const origin = {
        originName: originName,
        originType: originType
      }
      const data = (
        await fetchInterstitial(entryUid, contentTypeUid, {
          ...context,
          engagement: origin
        })
      ).data
      setScreenData(formatSectionData(data, HPX_INTERSTITIAL_TYPE))
    }
    getScreenData()
  }, [])

  useEffect(() => {
    if (screenData) {
      trackPageViewSimpleUIEvent(
        HPX_INTERSTITIAL_TYPE,
        screenData.campaignName,
        screenData.friendlyName || screenData.campaignName
      )
    }
  }, [screenData])

  const onClick = (cta) => {
    if (cta?.cta_type?.toLowerCase() === 'close') {
      setTimeout(() => {
        triggerEvent({ cta })
      }, 200)
    } else {
      triggerEvent({ cta })
      // close the interstitial if the clicked button is primary action button.
      setTimeout(() => {
        triggerEvent({ cta: { cta_type: 'close' } })
      }, 200)
    }
  }
  if (!screenData) {
    return null
  }
  return <Interstitial {...screenData} onButtonClick={onClick} />
}

export default InterstitialScreen
