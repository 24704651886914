export const getTilesSeenFromStorage = () => {
  const tilesSeen = sessionStorage.getItem('tilesSeen')
  return tilesSeen ? tilesSeen.split(',') : []
}

export const setTilesSeenToStorage = (tiles) => {
  if (tiles && tiles.length) {
    const tilesSeen = getTilesSeenFromStorage()
    const newTilesSeen = tiles.map((tile) => tile.id)
    sessionStorage.setItem(
      'tilesSeen',
      [...tilesSeen, ...newTilesSeen].toString()
    )
  } else {
    sessionStorage.setItem('tilesSeen', '')
  }
}
