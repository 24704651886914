import axios from './interceptors'
import { getUserInfo } from './persistData'
// import { HPX_INTERSTITIAL_TYPE } from './constants'

const getHeaders = async () => {
  console.log(await getUserInfo())
  const token = (await getUserInfo()).tokenValue
  if (token) {
    const header = {}
    header.authorization = `Bearer ${token}`
    return header
  }
  return null
}

export const fetchSectionInfo = async (type, body) => {
  const result = await axios({
    url: `/engagements/${type}`,
    method: 'POST',
    headers: await getHeaders(),
    data: {
      ...body
    },
    retry: 1
  })
  return result
  // return { data: mockResult[type] }
}

export const fetchInterstitial = async (entryUid, contentTypeUid, body) => {
  const result = await axios({
    url: `/engagements/${contentTypeUid}/${entryUid}`,
    method: 'POST',
    headers: await getHeaders(),
    data: {
      ...body
    }
  })
  return result
}
