import styled from 'styled-components'
import ButtonGroup from '@veneer/core/dist/scripts/button_group'

const textDirection = (dir) => (dir === 'rtl' ? 'right' : 'left')

export const BannerSection = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ backgroundGradient }) =>
    backgroundGradient ||
    `linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8) 2.74%,
    rgba(0, 0, 0, 0) 88.48%
  )`};
  width: 100%;
  border-radius: 0.7rem 0 0;
  position: relative !important;

  & > div {
    background: ${({ background }) => background};
    background-size: cover;
    background-repeat: no-repeat;
    padding: ${({ textBoxVariation }) => {
      if (textBoxVariation === 'bottom') {
        return ({ theme: { dir } }) =>
          dir === 'rtl' ? '6rem 8rem 2rem 0' : '6rem 0 2rem 8rem'
      }
      if (textBoxVariation === 'center') {
        return ({ theme: { dir } }) =>
          dir === 'rtl' ? '4rem 8rem 4rem 0' : '4rem 0 4rem 8rem'
      }
    }};
  }
`

export const BannerTextBox = styled.div`
  text-align: ${({ theme: { dir } }) => textDirection(dir)};
`

export const BannerHeadline = styled.h1`
  font-size: 2rem;
  line-height: 2.8rem;
  margin: 0;
  font-weight: normal;
  letter-spacing: 0.32px;
  color: ${({ color, theme: { styles } }) => color || styles.colorForeground};
`
export const BannerTagLine = styled.h2`
  font-size: 3.2rem;
  line-height: 4rem;
  margin: 0;
  font-weight: normal;
  letter-spacing: 0.32px;
  color: ${({ color, theme: { styles } }) => color || styles.colorForeground};
`

export const BannerHighlighter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.6rem 0;
  width: 9rem;
  height: 2.4rem;
  background: #15c96d; //todo: add to theme
  color: #363645; // todo: add to theme
  border-radius: 6.4rem;
  font-size: 1rem;
  line-height: 1.6rem;
  margin-bottom: 1.6rem;
  text-align: center;
  word-break: break-word;
`

export const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: 2.4rem;
`
