import { jWebReady } from '@jarvis/jweb-core'

/**
 * Checks the Native Application to determine if the user details.
 * userInfo {
 *    tokenValue: "[token]",
 *    account: {
 *        emailAddress: "[email address]",
 *        givenName: "[first name]",
 *        familyName: "[last name]",
 *        accountId: "[account id]",
 *    },
 *    expiresAt: "[2021-02-03T06:51:02.000Z]"
 * }
 * @param {boolean} refresh Token refresh
 * @returns {promise} userInfo
 */
export const checkSignInAndGetUserDetails = async () => {
  try {
    const jweb = await jWebReady
    if (jweb && jweb.isNative) {
      const { Auth } = jweb.Plugins
      const isLoggedIn = await Auth.isLoggedIn()
      if (isLoggedIn.value) {
        return await getUserAuthDetails()
      }
    }
  } catch (error) {
    console.error(`SEND CHECK SIGN IN AUTH ERROR: ${error}`)
  }
}

/**
 * Fetches the Auth information from the Native App.
 * Payload {
 *    tokenValue: "[token]",
 *    account: {
 *        emailAddress: "[email address]",
 *        givenName: "[first name]",
 *        familyName: "[last name]",
 *        accountId: "[account id]",
 *    },
 *    expiresAt: "[2021-02-03T06:51:02.000Z]"
 * }
 * @param {boolean} refresh Token refresh
 * @returns {promise} authInfo
 */
export const getUserAuthDetails = async () => {
  try {
    const jweb = await jWebReady
    if (jweb && jweb.isNative) {
      const { Auth } = jweb.Plugins
      const userInfo = await Auth.getToken({
        tokenProviderOptions: {
          tokenType: 'user'
        }
      })
      return userInfo
    }
    return {}
  } catch (error) {
    console.error(`SEND AUTH ERROR: ${error}`)
    return {
      tokenValue: '',
      account: {}
    }
  }
}

/**
 * Fetches the Auth information from the Native App.  Parse out the token value from payload.
 * Payload {
 *    tokenValue: "[token]",
 *    account: {
 *        emailAddress: "[email address]",
 *        givenName: "[first name]",
 *        familyName: "[last name]",
 *        accountId: "[account id]",
 *    },
 *    expiresAt: "[2021-02-03T06:51:02.000Z]"
 * }
 * @param {boolean} refresh Token refresh
 * @returns {string} JWT Value or empty string
 * @deprecated
 */
export const getUserAuthToken = async () => {
  const authDetails = await getUserAuthDetails()
  return authDetails
}

/**
 * Fetches the Auth information from the Native App.  Parse out the account details from payload.
 * Payload {
 *    tokenValue: "[token]",
 *    account: {
 *        emailAddress: "[email address]",
 *        givenName: "[first name]",
 *        familyName: "[last name]",
 *        accountId: "[account id]",
 *    },
 *    expiresAt: "[2021-02-03T06:51:02.000Z]"
 * }
 * @param {boolean} refresh Token refresh
 * @returns {object} account or empty object
 * @deprecated
 */
export const getUserAuthAccount = async () => {
  const authDetails = await getUserAuthDetails()
  return authDetails.account
}
