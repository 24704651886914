import React, { useLayoutEffect, useEffect } from 'react'
import { useTheme } from 'styled-components'
import { usePublishHeight } from '../../hooks/usePublishHeight'

import {
  TilesSectionContainer,
  TilesSectionBody,
  TilesSectionHeader,
  TilesSectionTitle,
  TileWrapper,
  StyledLink
} from './MultiRowWidget.styles.js'
import { MultiRowWidgetProps } from '../../types/welcomepage'
import { trackTileViewSimpleUIEvent } from '../../utils/simpleUIAnalytics'
import {
  getTilesSeenFromStorage,
  setTilesSeenToStorage
} from '../../utils/analytics'

const MultiRowWidget: React.FC<MultiRowWidgetProps> = ({
  title,
  TileTemplate,
  isShowAllDisplayed,
  showAllText,
  tileList,
  onShowAllClicked,
  tilesCount,
  includedTileType,
  rowCount,
  onTileClick,
  onTileDismiss
}: MultiRowWidgetProps) => {
  const theme = useTheme()
  const { sectionRef, publishHeight } = usePublishHeight(
    includedTileType,
    theme
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', publishHeight)
    }
    return () => {
      window.removeEventListener('resize', publishHeight)
    }
  }, [])

  useLayoutEffect(() => {
    if (tileList?.length) {
      const visibleTilecount = tilesCount * rowCount
      if (tileList.length < tilesCount * rowCount) {
        tileList.length
      }
      tileList.forEach((t, index, thisArray) => {
        thisArray[index].position = `${index + 1}of${visibleTilecount}`
      })
      trackTilesSeen()
    }
  }, [tileList, tilesCount])

  useLayoutEffect(() => {
    setTimeout(() => {
      publishHeight()
    }, 100)
  }, [tileList, tilesCount])

  const trackTilesSeen = () => {
    const tilesSeen = getTilesSeen()
    onTileSeen(tilesSeen, includedTileType)
  }

  const getTilesSeen = () => {
    let seenTiles: Array<any> = []
    // check if tile is within current view,
    // this can't be checked unless we know bounds of whole welcome page
    // for now, assuming that all tiles are seen on screen load
    seenTiles = tileList.slice(0, tilesCount * rowCount)
    return seenTiles
  }

  const onTileSeen = (tiles: any[], controlType: string) => {
    if (tiles && tiles.length) {
      const alreadySeenTiles = getTilesSeenFromStorage()
      const newSeenTiles = tiles.filter(
        (tile) => !alreadySeenTiles.includes(tile.id)
      )
      if (newSeenTiles.length) {
        trackTileViewSimpleUIEvent(newSeenTiles, controlType)
        setTilesSeenToStorage(newSeenTiles)
      }
    }
  }
  const onClick = (tile: any, tileType: string, index: number) => {
    const alreadySeenTiles = getTilesSeenFromStorage()
    if (alreadySeenTiles.indexOf(tile.id) === -1) {
      trackTileViewSimpleUIEvent([tile], tileType)
      setTilesSeenToStorage([tile])
    }
    onTileClick(tile, tileType, index)
  }

  return (
    <TilesSectionContainer ref={sectionRef} data-testid="mutirow-container">
      <TilesSectionHeader>
        <TilesSectionTitle data-testid="title">{title}</TilesSectionTitle>
        {isShowAllDisplayed && (
          <StyledLink
            id="see_all_button"
            data-testid="see_all_button"
            onClick={(e) => {
              onShowAllClicked(e)
            }}
          >
            {showAllText}
          </StyledLink>
        )}
      </TilesSectionHeader>
      <TilesSectionBody>
        {tileList?.map((tile, index) => {
          if (index < tilesCount * rowCount) {
            return (
              <TileWrapper tilesWidth={`${100 / tilesCount}%`} key={index}>
                <TileTemplate
                  data-testid={`index${tile.type}`}
                  {...tile}
                  index={index}
                  onClick={() => onClick(tile, includedTileType, index)}
                  onDismiss={() => onTileDismiss(tile, includedTileType)}
                />
              </TileWrapper>
            )
          }
        })}
      </TilesSectionBody>
    </TilesSectionContainer>
  )
}

export default MultiRowWidget
