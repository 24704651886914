import { jWebReady } from '@jarvis/jweb-core'
import { PUBLISHER_ID } from '../constants'

// Use this function to publish any event to Native other than data collection events
export const publishEvent = async (eventName, eventData = {}) => {
  try {
    console.log(`Welcome page::${eventName} : ${JSON.stringify(eventData)}`)
    const jweb = await jWebReady
    if (jweb && jweb.isNative) {
      const { EventService } = jweb.Plugins

      let publisher = await EventService.createPublisher(
        `${PUBLISHER_ID}.${eventName}`
      )
      if (publisher && publisher.errorType !== undefined) {
        console.error('Error creating ', publisher)
      }

      await publisher.publish(eventName, eventData)

      //Adding backward compatibility until app team update publishedID on their end
      const oldPublisher = await EventService.createPublisher(PUBLISHER_ID)
      if (oldPublisher && oldPublisher.errorType !== undefined) {
        console.error('Error creating ', oldPublisher)
      }

      await oldPublisher.publish(eventName, eventData)
    }
  } catch (error) {
    console.error('events::publishEvent: %o', error)
  }
}
