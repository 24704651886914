import React, { useEffect } from 'react'
import Banner from '../../components/Banner'
import { ThemeProvider } from 'styled-components'
import { MfePropsType } from '../../types/mfeProps'
import {
  Container,
  ActionSection,
  PromotionSection,
  NextStepsSection,
  GlobalStyle
} from './Welcome.styles'
import { useInit } from '../../hooks/useInit'
import { useTheme } from '../../hooks/useTheme'
import { useTilesCount } from '../../hooks/useTilesCount'
import { useAppContext } from '../../hooks/useAppContext'

import NextStepsTile from '../../components/NextStepsTile'
import ActionTile from '../../components/ActionTile'
import PromotionTile from '../../components/PromoTile'
import CarouselWidgetA from '../CarouselWidgetA'
import MultiRowWidget from '../MultiRowWidget'
import InterstitialScreen from '../InterstitialScreen'
import {
  trackTileClickedSimpleUIEvent,
  trackTileDismissalSimpleUIEvent,
  trackLinkClickSimpleUIEvent,
  trackTileSnoozeSimpleUIEvent
} from '../../utils/simpleUIAnalytics'
import {
  All_SECTIONS,
  HPX_ACTIONS_TYPE,
  HPX_INTERSTITIAL_TYPE,
  HPX_NEXTSTEPS_TYPE,
  HPX_PROMOTIONAL_TYPE,
  MAXIMUM_PROMOTIONAL_TILES_MOBILE,
  TILE_DISMISS,
  TILE_SNOOZE
} from '../../utils/constants'

import { triggerEvent } from '../../jweb/callToAction'
import axios from '../../utils/interceptors'
import { getTileWidth } from '../../utils/helpers'
import '../../styles/global.css'

const WelcomePage = (props: MfePropsType) => {
  axios.defaults.baseURL = props.properties?.baseUrl
  const searchParams = new URLSearchParams(window.location.search)
  const sectionType = searchParams.get('type')
  const includePadding = searchParams.get('space')
  const rowCount = parseInt(searchParams.get('rows'))
  const bgcolor = searchParams.get('bgcolor')

  const sectionsToDisplay =
    sectionType && All_SECTIONS.includes(sectionType)
      ? [sectionType]
      : sectionType === HPX_INTERSTITIAL_TYPE
      ? []
      : All_SECTIONS

  useEffect(() => {
    console.log(`MFE loaded at: ${performance.now()}`)
  }, [])

  const { isAppContextFetched } = useAppContext(sectionType)
  const { bannerInfo, actionsInfo, promotionsInfo, nextStepsInfo, pageReady } =
    useInit(sectionsToDisplay, isAppContextFetched)
  const theme = useTheme(isAppContextFetched)
  const { platform } = theme
  const tilesCount = useTilesCount()

  if (sectionType === HPX_INTERSTITIAL_TYPE && isAppContextFetched) {
    const entryUid = searchParams.get('entryUid')
    const contentTypeUid = searchParams.get('contentTypeUid')
    const originName = searchParams.get('originName')
    const originType = searchParams.get('originType')
    return (
      <>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <InterstitialScreen
            entryUid={entryUid}
            contentTypeUid={contentTypeUid}
            originName={originName}
            originType={originType}
          />
        </ThemeProvider>
      </>
    )
  }

  const onTileClick = (tile: any, tileType: string, index: any): void => {
    const { campaignName, friendlyName, campaignType, taxonomyName } = tile
    trackTileClickedSimpleUIEvent(
      campaignName,
      friendlyName || campaignName,
      campaignType,
      tileType,
      index,
      taxonomyName
    )
    triggerEvent(tile, tileType, theme.mode)
  }

  const onTileDismiss = (tile: any, tileType?: string): void => {
    const { campaignName, friendlyName, campaignType, taxonomyName } = tile
    trackTileDismissalSimpleUIEvent(
      TILE_DISMISS,
      friendlyName || campaignName,
      campaignType,
      tileType,
      taxonomyName
    )
  }

  const onTileSnooze = (tile: any, tileType?: string): void => {
    const { campaignName, friendlyName, campaignType, taxonomyName } = tile
    trackTileSnoozeSimpleUIEvent(
      TILE_SNOOZE,
      friendlyName || campaignName,
      campaignType,
      tileType,
      tile,
      taxonomyName
    )
  }

  const onShowAllClicked = (sectionContent: any, tileType: string): void => {
    trackLinkClickSimpleUIEvent({
      controlName: 'seeAll',
      controlDetail: tileType,
      controlType: tileType
    })
    const cta = {
      cta: {
        cta_type: 'deeplink',
        target_url_or_deeplink: sectionContent?.action_section_link?.href
      }
    }
    triggerEvent(cta)
  }

  if (!pageReady) {
    return null
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Container bgcolor={bgcolor} data-testid="welcome-container">
          {/* Banner */}
          {Object.keys(bannerInfo).length ? (
            <Banner
              {...bannerInfo}
              id={bannerInfo['id']}
              name={bannerInfo['name']}
              headline={bannerInfo['headline']}
              tagline={bannerInfo['tagline']}
              backgroundImage={bannerInfo['backgroundImage']}
              headlineColor={bannerInfo['headlineColor']}
              taglineColor={bannerInfo['taglineColor']}
            ></Banner>
          ) : null}

          {/* next steps section */}
          {nextStepsInfo?.engagements?.length ? (
            <NextStepsSection
              data-testid="next-step-section"
              includePadding={sectionsToDisplay?.length > 1 || includePadding}
            >
              <CarouselWidgetA
                title={nextStepsInfo['sectionHeader']?.title}
                tileList={nextStepsInfo.engagements}
                TileTemplate={NextStepsTile}
                isSwipeEnabled={true}
                tilesCount={tilesCount['NextStepTile']}
                includedTileType={HPX_NEXTSTEPS_TYPE}
                isDragAllowed={false}
                onTileClick={onTileClick}
                onTileDismiss={onTileDismiss}
                onTileSnooze={onTileSnooze}
                isCountDisplayed={true}
              ></CarouselWidgetA>
            </NextStepsSection>
          ) : null}

          {/* action section */}
          {actionsInfo?.engagements?.length ? (
            <ActionSection
              data-testid="action-section"
              includePadding={sectionsToDisplay?.length > 1 || includePadding}
            >
              <CarouselWidgetA
                title={actionsInfo['sectionHeader']?.title}
                tileList={actionsInfo.engagements}
                TileTemplate={ActionTile}
                isSwipeEnabled={true}
                isShowAllDisplayed={true}
                showAllText={
                  actionsInfo['sectionHeader']?.action_section_link?.title
                }
                tilesCount={tilesCount['ActionTile']}
                onShowAllClicked={() => {
                  onShowAllClicked(actionsInfo['sectionHeader'], 'action')
                }}
                includedTileType={HPX_ACTIONS_TYPE}
                isDragAllowed={false}
                onTileClick={onTileClick}
                onTileDismiss={onTileDismiss}
              ></CarouselWidgetA>
            </ActionSection>
          ) : null}

          {/* Promotions section */}
          {promotionsInfo?.engagements?.length ? (
            <PromotionSection
              includePadding={sectionsToDisplay?.length > 1 || includePadding}
            >
              {rowCount === 1 ? (
                <CarouselWidgetA
                  title={promotionsInfo['sectionHeader']?.title}
                  tileList={promotionsInfo.engagements.slice(
                    0,
                    MAXIMUM_PROMOTIONAL_TILES_MOBILE
                  )}
                  TileTemplate={PromotionTile}
                  tileWidth={getTileWidth(HPX_PROMOTIONAL_TYPE, platform)}
                  isSwipeEnabled={false}
                  isShowAllDisplayed={false}
                  includedTileType={HPX_PROMOTIONAL_TYPE}
                  isDragAllowed={true}
                  onTileClick={onTileClick}
                  onTileDismiss={function (): void {
                    throw new Error('Function not implemented.')
                  }}
                ></CarouselWidgetA>
              ) : (
                <MultiRowWidget
                  title={promotionsInfo['sectionHeader']?.title}
                  tileList={promotionsInfo.engagements}
                  TileTemplate={PromotionTile}
                  tilesCount={tilesCount['PromotionTile']}
                  includedTileType={HPX_PROMOTIONAL_TYPE}
                  onTileClick={onTileClick}
                  rowCount={rowCount || 2}
                  onTileDismiss={function (): void {
                    throw new Error('Function not implemented.')
                  }}
                ></MultiRowWidget>
              )}
            </PromotionSection>
          ) : null}
        </Container>
      </ThemeProvider>
    </>
  )
}

export default WelcomePage
