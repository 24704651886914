export const ANDROID = 'android'
export const MAC = 'mac'
export const IOS = 'ios'
export const WINDOWS = 'windows'
export const WEB = 'web'
export const PUBLISHER_ID = 'com.hp.jarvis.hpxwelcomepage.app.publisher'
export const DATA_COLLECTION_PUBLISHER_ID =
  'com.hp.jarvis.hpxwelcomepage.datacollection.publisher'

export const EVENT_SCHEMA_VERSION = '1.4.0'
export const SIMPLE_UI_SCHEMA_VERSION = '1.1.0'
export const SIMPLE_UI_GUN =
  'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1'
export const SIMPLE_UI_CATEGORY = 'simpleUi'

export const AUTH_PUBLISHER_ID = 'com.hp.jarvis.auth.event.publisher'
export const AUTH_CHANGE_EVENT = 'com.hp.jarvis.auth.event.login.change'

export const DEEPLINK = 'deeplink'
export const URL = 'url'
export const INTERSTITIAL = 'interstitial'
export const DSP = 'dsp'
export const CREATEACCOUNT = 'createaccount'
export const SIGNIN = 'signin'
export const HPPLUSCONVERSION = 'hpplusconversion'
export const CLOSE = 'close'
export const CALLTYPE = 'P2'
export const CALLVALUE = 'YETI'
