import styled from 'styled-components'
import IconEllipsis from '@veneer/core/dist/scripts/icons/icon_ellipsis'
import ContextualMenu from '@veneer/core/dist/scripts/contextual_menu'

const textDirection = (dir) => (dir === 'rtl' ? 'right' : 'left')

export const CardContainer = styled.div`
  width: calc(100%);
`

export const Card = styled.div`
  height: auto;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding: 1.6rem;
  border-radius: 0.8rem;
  box-sizing: border-box;
  white-space: normal;
  cursor: pointer;
  background-color: ${({ theme: { styles } }) =>
    styles.colorContainerBackground};
  margin: 0.2rem;
  &:hover {
    background-color: ${({ theme: { styles } }) =>
      styles.colorHoverBackgroundFull};
    color: ${({ theme: { styles } }) => styles.colorHoverText};
    div,
    h1,
    path {
      color: ${({ theme: { styles } }) => styles.colorHoverText};
    }
  }
`
export const CardImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`
export const CardImage = styled.img`
  max-height: 2.4rem;
`
export const CardImagePlaceholder = styled.div`
  padding: 0.2rem 0.2rem;
  width: 2.4rem;
  height: 2.4rem;
  border: 1px solid ${({ theme: { styles } }) => styles.colorForegroundLight};
  border-radius: 50%;
`
export const Title = styled.h1`
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: normal;
  color: ${({ theme: { styles } }) => styles.colorTileTitle};
  -webkit-line-clamp: ${({ computedFontSize }) =>
    computedFontSize > 16 ? 1 : 2};
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  -webkit-box-orient: vertical;
  padding-top: 0.8rem;
  text-align: ${({ theme: { dir } }) => textDirection(dir)};
  word-break: break-all;
  margin-bottom: 12px;
`
export const SubTitle = styled.div`
  color: ${({ theme: { styles } }) => styles.colorTilesubTitle};
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: normal;
  -webkit-line-clamp: ${({ computedFontSize }) =>
    computedFontSize > 16 ? 1 : 3};
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  text-align: ${({ theme: { dir } }) => textDirection(dir)};
  word-break: break-all;
`
export const StyledIconEllipsis = styled(IconEllipsis)`
  position: absolute;
  top: 0.1rem;
  ${({ theme: { dir } }) => (dir === 'rtl' ? 'left: 0.1rem' : 'right: 0.1rem')};
  height: 2rem;
  width: 2rem;
  transform: rotate(90deg);
  color: ${({ theme: { styles } }) =>
    styles.colorForegroundMedium} !important};};
  ${({ theme: { styles }, isContextualMenuOpen }) =>
    !!isContextualMenuOpen &&
    `background-color: ${styles.colorHoverBackground}`};
  &:hover {
    background-color: ${({ theme: { styles } }) =>
      styles.colorHoverBackground};};
      border-radius: 8px;
      color: ${({ theme: { styles } }) => styles.colorHoverText};
      path {
        color: ${({ theme: { styles } }) => styles.colorHoverText};
      }
  }
`
export const ContextMenu = styled(ContextualMenu)`
  & > div {
    margin: 0 !important;
    & > div {
      min-width: unset;
      height: auto;
      & > ul {
        padding: 3px;
        & > li {
          min-width: unset;
          min-height: auto;
          padding: 5px;
          & > span {
            font-size: 15px;
          }
        }
      }
    }
  }
`
