import { jWebReady } from '@jarvis/jweb-core'
import { WEB } from './constants'

export const getHostLocaleDetailsWeb = async () => {
  try {
    const jweb = await jWebReady
    // Getting locale from Jshell, if welcome page is loaded in browser
    if (jweb.platform === WEB) {
      if (window.Shell?.v1?.localization) {
        const localization = window.Shell?.v1?.localization
        return {
          locale: localization.locale,
          language: localization.language,
          appCountry: localization.country
        }
      }
    }
    return {}
  } catch (error) {
    console.error(`Localization plugin not available`)

    return {}
  }
}
