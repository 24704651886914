import { getUserAuthDetails } from '../jweb/plugins/auth'
import jwt_decode from 'jwt-decode'
import { featureToDeviceControlEnum } from './constants'

let userInfo = {}
let hostInfo = {}
let featureList = []
let mobileHost = false

export const setUserInfo = (info = {}) => {
  userInfo = info
}

export const getUserInfo = async () => {
  try {
    const token = userInfo.tokenValue
    if (token) {
      const expiresOn = jwt_decode(token).exp
      const currentTimeInSec = Math.floor(new Date().getTime() / 1000)
      if (expiresOn && expiresOn - currentTimeInSec <= 300) {
        // Refetch token if it expires in 5 min;
        const info = await getUserAuthDetails()
        setUserInfo(info)
      }
    }
    return userInfo
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`TOKEN FETCH ERROR ${error}`)
    return userInfo
  }
}

export const setDeviceInfo = (info) => {
  hostInfo = { ...info }
}

export const getDeviceInfo = () => {
  return hostInfo
}

export const setMobileHost = (host) => {
  mobileHost = host
}

export const getMobileHost = () => {
  return mobileHost
}

export const setFeatureList = (features) => {
  if (features) {
    Object.keys(featureToDeviceControlEnum).forEach((feature) => {
      if (features[feature] === true) {
        featureList.push(featureToDeviceControlEnum[feature])
      }
    })
  }
}

export const getFeatureList = () => {
  return featureList
}

export const getCurrentContext = () => {
  return {
    language: hostInfo.language || 'en',
    locale:
      hostInfo.locale && typeof hostInfo.locale === 'string'
        ? hostInfo.locale
        : 'en-US',
    appCountry: hostInfo.appCountry || 'US',
    appVersion: hostInfo.appVersion,
    property: `hpx-${hostInfo.platform || 'web'}`,
    appInstanceId: hostInfo.uuid || '12345' // mock app instance id to bypass required param check
  }
}
