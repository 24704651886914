import styled, { createGlobalStyle, css } from 'styled-components'
import { ANDROID, IOS, MAC } from '../../jweb/constants'
// You can use variables from veneer tokens and do something like the below
// import tokens from '@veneer/tokens'
//   background-color: ${tokens.colorHpBlue2};

export const GlobalStyle = createGlobalStyle`

  html, body {
    padding: 0;
    margin: 0;
    ${({ theme: { platform } }) =>
      [ANDROID, IOS, MAC].includes(platform)
        ? `
    font-family: 'HP Simplified Regular', 'Forma DJR Micro', arial, sans-serif !important;
    letter-spacing: normal !important;
    `
        : `font-family: 'Forma DJR Micro', arial, sans-serif !important; `};
    font-size: 10px !important;
    // Added to hide scroll bar that is displayed before dynamic height event reaches native app
    -ms-overflow-style: none;
    background-color: ${({ theme: { styles }, bgcolor }) =>
      bgcolor ? `#${bgcolor}` : styles.colorPageBg};
    touch-action: pan-x pan-y;
  }

  div {
    box-sizing: border-box;
  }

  h1,h2,h3,h4,h5,h6{
    ${({ theme: { platform } }) =>
      [ANDROID, IOS, MAC].includes(platform)
        ? `
      font-family: HP-simplified Regular, 'Forma DJR Micro', arial, sans-serif !important;`
        : `font-family: 'Forma DJR Micro', arial, sans-serif !important;`}
  }

  // https://www.npmjs.com/package/focus-visible
  // polyfill to focus-visible

  *:focus:not([data-focusvisible-polyfill]) {
    outline: none;
  }

  [data-focusvisible-polyfill]{
    outline: solid;
  }
`

export const Container = styled.div`
  text-align: center;
  background-color: ${({ theme: { styles }, bgcolor }) =>
    bgcolor ? `#${bgcolor}` : styles.colorPageBg};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

const sectionStyle = css`
  width: 100%;
  margin: auto;
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
`
export const ActionSection = styled.div`
  ${sectionStyle}
  ${({ includePadding }) =>
    includePadding ? `padding: 4rem 4rem 0 4rem;` : ``}
`
export const NextStepsSection = styled.div`
  ${sectionStyle}
  ${({ includePadding }) =>
    includePadding ? `padding: 4rem 4rem 0 4rem;` : ``}
`
export const PromotionSection = styled.div`
  ${sectionStyle}
  ${({ includePadding }) =>
    includePadding
      ? `
  padding: 4rem 4rem 0 4rem;
  margin-bottom: 2rem`
      : ``}
`
