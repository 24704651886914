import {
  HPX_ACTIONS_TYPE,
  HPX_BANNER_TYPE,
  HPX_INTERSTITIAL_TYPE,
  HPX_NEXTSTEPS_TYPE,
  HPX_PROMOTIONAL_TYPE,
  Tile_WIDTHS
} from './constants'

export const getComputedfontSize = (elRef) => {
  return elRef && elRef.current
    ? Number(window.getComputedStyle(elRef.current).fontSize.split('px')[0])
    : 0
}

export const queryStringFromObject = (obj, withInitialParam) => {
  const pairs = Object.entries(obj)
  return pairs.reduce(
    (acc, [key, value], index) => {
      if (key && value) {
        return index ? `${acc}&${key}=${value}` : `${acc}${key}=${value}`
      }
      return acc
    },
    withInitialParam ? '&' : '?'
  )
}

export const formatSectionData = (data, sectionName) => {
  let engagements
  let sectionHeader = {}
  if (data) {
    switch (sectionName) {
      case HPX_BANNER_TYPE: {
        if (data.engagements[0]) {
          return {
            id: data.engagements[0].uid,
            name: data.engagements[0].campaign_name,
            taxonomyName:
              data.engagements[0].taxonomy_information?.taxonomy_name,
            friendlyName: data.engagements[0].variations?.friendly_name,
            headline: data.engagements[0].variations.headline_text,
            tagline: data.engagements[0].variations.banner_tagline,
            headlineColor: data.engagements[0].variations.headline_color,
            taglineColor: data.engagements[0].variations.tagline_color,
            backgroundImage:
              data.engagements[0].variations.background_image?.asset?.url
          }
        } else {
          return {}
        }
      }
      case HPX_ACTIONS_TYPE: {
        engagements = data.engagements?.map((e) => ({
          id: e.uid,
          campaignName: e.campaign_name,
          taxonomyName: e.taxonomy_information?.taxonomy_name,
          friendlyName: e.variations?.friendly_name,
          dismissible: e.dismissible,
          title: e.variations.action_title,
          subTitle: e.variations.action_description,
          image: e.variations.icon?.url,
          cta: e.variations.cta
        }))
        if (data.sectionHeader) {
          const { title, action_section_link } = data.sectionHeader
          sectionHeader = {
            title,
            action_section_link
          }
        }
        return { sectionHeader, engagements }
      }
      case HPX_NEXTSTEPS_TYPE: {
        engagements = data.engagements?.map((e) => ({
          id: e.uid,
          campaignName: e.campaign_name,
          taxonomyName: e.taxonomy_information?.taxonomy_name,
          friendlyName: e.variations?.friendly_name,
          dismissible: e.dismissible,
          title: e.variations.next_steps_title,
          subTitle: e.variations.next_steps_description,
          image: e.variations.tile_image?.url,
          cta: e.variations.cta
        }))
        if (data.sectionHeader) {
          const { title, next_steps_description, number_of_tiles } =
            data.sectionHeader
          sectionHeader = {
            title,
            next_steps_description,
            number_of_tiles
          }
        }
        return { sectionHeader, engagements }
      }
      case HPX_PROMOTIONAL_TYPE: {
        engagements = data.engagements?.map((e) => ({
          id: e.uid,
          campaignName: e.campaign_name,
          taxonomyName: e.taxonomy_information?.taxonomy_name,
          friendlyName: e.variations?.friendly_name,
          campaignType: e.campaign_type,
          dismissible: e.dismissible,
          hasTitleTag: e.tag_or_subtitle?.display_tag,
          image: e.variations.tile_image?.asset?.url,
          title: e.variations.tile_title,
          description: e.variations.description_copy,
          cta: e.variations.cta
        }))
        sectionHeader = {
          title: data.sectionHeader?.title
        }
        return { sectionHeader, engagements }
      }
      case HPX_INTERSTITIAL_TYPE: {
        const interstitial = data.engagements[0]
        return {
          campaignName: interstitial.campaign_name,
          taxonomyName: interstitial.taxonomy_information?.taxonomy_name,
          friendlyName: interstitial.variations?.friendly_name,
          id: interstitial.uid,
          headline: interstitial.variations.interstitial_headline,
          description: interstitial.variations.interstitial_description,
          textBoxVariation: interstitial.variations.alignment,
          icon: interstitial.variations.icon?.asset?.url,
          image: interstitial.variations.image?.asset?.url,
          mobileImage: interstitial.variations?.mobile_image?.asset?.url,
          listHeadline: interstitial.variations?.list_headline,
          listItem: interstitial.variations?.list_item,
          ctaButtons: interstitial.variations.cta,
          disclaimer: interstitial.variations.disclaimer,
          disclaimerHeadline: interstitial.variations.disclaimer_headline
        }
      }
      default: {
        break
      }
    }
  }

  return data
}

export const getTileWidth = (type, platform) => {
  if (Tile_WIDTHS[type] && Tile_WIDTHS[type][platform]) {
    return Tile_WIDTHS[type][platform]
  }
  return Tile_WIDTHS[type]['web']
}
