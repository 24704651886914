import React from 'react'
import {
  InterstitialSection,
  InterstitialHeadline,
  InterstitialListItems,
  InterstitialDescription,
  InterstitialButtons,
  InterstitialRightContainer,
  InterstitialIcon,
  DisclaimerHeadline,
  DisclaimerDescription,
  FooterDiv,
  ImageContainer,
  MobileImageContainer
} from './Interstitial.styles'
import Button from '@veneer/core/dist/scripts/button'
import { trackButtonClickSimpleUIEvent } from '../../utils/simpleUIAnalytics'
import { useFocalPointImage } from '../../hooks/useFocalPointImage'
interface InterstitialProps {
  id: string
  image: string
  icon: string
  mobileImage: string
  headline: string
  description: string
  listHeadline: string
  listItem?: Array<any>
  ctaButtons?: Array<any>
  onButtonClick?: any
  textBoxVariation?: string
  campaignName?: string
  disclaimer?: string
  disclaimerHeadline?: string
}

const Interstitial: React.FC<InterstitialProps> = ({
  id,
  image,
  icon,
  mobileImage,
  headline,
  description,
  listHeadline,
  listItem,
  ctaButtons,
  onButtonClick,
  textBoxVariation,
  campaignName,
  disclaimer,
  disclaimerHeadline
}: InterstitialProps) => {
  useFocalPointImage('image', image)
  useFocalPointImage('mobileImage', mobileImage || image)

  return (
    <InterstitialSection data-testid="interstitial-screen" id={id}>
      <ImageContainer>
        <img src={image} id={`image`} />
      </ImageContainer>
      <MobileImageContainer>
        <img src={mobileImage || image} id={`mobileImage`} />
      </MobileImageContainer>
      <InterstitialRightContainer
        textBoxVariation={textBoxVariation ? textBoxVariation : 'Left'}
      >
        {icon && <InterstitialIcon icon={`url(${icon})`} />}
        <div>
          <InterstitialHeadline data-testid="headLine" id="headLine">
            {headline}
          </InterstitialHeadline>
          <InterstitialDescription
            data-testid="description"
            id="description"
            extraWhiteSpace={!!listItem?.length}
            dangerouslySetInnerHTML={{
              __html: `${description} ${
                listHeadline ? '<br> <br>' + listHeadline : ''
              }`
            }}
          ></InterstitialDescription>
          {!!listItem?.length && (
            <InterstitialListItems>
              {listItem?.map((item, index) => (
                <li key={index}> {item} </li>
              ))}
            </InterstitialListItems>
          )}
          {ctaButtons && (
            <InterstitialButtons>
              {ctaButtons.map((cta, index) => {
                return (
                  <Button
                    key={`cta-buttons-${index}`}
                    data-testid={`cta-buttons-${index}`}
                    id={`cta-buttons-${index}`}
                    appearance={cta.cta_style?.toLowerCase()}
                    onClick={() => {
                      trackButtonClickSimpleUIEvent({
                        controlName: `CtaButton${index}`,
                        controlDetail: campaignName,
                        controlType: 'hpxinterstitial'
                      })
                      onButtonClick(cta)
                    }}
                    small
                  >
                    {cta.target_url_or_deeplink?.title}
                  </Button>
                )
              })}
            </InterstitialButtons>
          )}
        </div>
        {disclaimer && (
          <FooterDiv>
            <DisclaimerHeadline
              data-testid="disclaimer-headLine"
              id="disclaimer-headLine"
            >
              {disclaimerHeadline}
            </DisclaimerHeadline>
            <DisclaimerDescription
              data-testid="disclaimer-description"
              id="disclaimer-description"
              dangerouslySetInnerHTML={{
                __html: `${disclaimer}`
              }}
            ></DisclaimerDescription>
          </FooterDiv>
        )}
      </InterstitialRightContainer>
    </InterstitialSection>
  )
}

export default Interstitial
