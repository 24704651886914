import React from 'react'
import WelcomePage from '../src/containers/WelcomePage'
import { ShellProps } from '../src/types/shell'
import { MfePropsType } from '../src/types/mfeProps'
import projectNames from '../src/configs/projectNames'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({ ...props }: MfePropsType) {
  const interfaces = window.Shell.v1
  const headTag = document.getElementsByTagName('HEAD')[0]
  const emulationTag = document.createElement('META')
  emulationTag.setAttribute('name', 'viewport')
  emulationTag.setAttribute(
    'content',
    'width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0'
  )
  // next line ensures insert is first element under <HEAD> tag
  headTag.insertBefore(emulationTag, headTag.firstChild)
  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <WelcomePage {...{ ...props, ...interfaces }} />
    </section>
  )
}
