import React from 'react'
import { useFocalPointImage } from '../../hooks/useFocalPointImage'
import {
  BannerSection,
  BannerHeadline,
  BannerTagLine,
  BannerTextBox,
  BannerHighlighter,
  StyledButtonGroup
} from './Banner.styles'
import Button from '@veneer/core/dist/scripts/button'
import { BannerProps } from '../../types/welcomepage'
import { trackButtonClickSimpleUIEvent } from '../../utils/simpleUIAnalytics'

const callToAction = (type: string, value: string): void => {
  console.log(`${type}: ${value}`)
}

const Banner: React.FC<BannerProps> = ({
  name,
  id,
  headline,
  tagline,
  backgroundImage,
  backgroundGradient,
  contentTag,
  ctaButtons,
  textBoxVariation,
  headlineColor,
  taglineColor,
  contentTagText
}: BannerProps) => {
  useFocalPointImage(id, backgroundImage)
  return (
    <BannerSection
      background={`url(${backgroundImage})`}
      backgroundGradient={backgroundGradient}
      textBoxVariation={textBoxVariation ? textBoxVariation : 'center'}
      id={id}
      name={name}
    >
      <div>
        {contentTag && (
          <BannerHighlighter id="contentTag">
            <span>{contentTagText}</span>
          </BannerHighlighter>
        )}
        <BannerTextBox>
          <BannerHeadline
            data-testid="headline"
            id="headline"
            color={headlineColor}
          >
            {headline}
          </BannerHeadline>
          <BannerTagLine
            data-testid="tagline"
            id="tagline"
            color={taglineColor}
          >
            {tagline}
          </BannerTagLine>
        </BannerTextBox>
        {ctaButtons?.length ? (
          <StyledButtonGroup orientation="horizontal">
            {ctaButtons.map((button, index) => {
              return (
                <Button
                  key={`cta-buttons-${index}`}
                  data-testid={`cta-buttons-${index}`}
                  id={`cta-buttons-${index}`}
                  appearance={button.type}
                  onClick={() => {
                    callToAction(button.cta.type, button.cta.value)
                    trackButtonClickSimpleUIEvent({
                      controlName: `CtaButton${index}`,
                      controlDetail: name,
                      controlType: 'hpxwelcomebanner'
                    })
                  }}
                  small
                >
                  {button.label.substring(0, 15)}
                </Button>
              )
            })}
          </StyledButtonGroup>
        ) : null}
      </div>
    </BannerSection>
  )
}

export default Banner
