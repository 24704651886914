import IconArrowLeft from '@veneer/core/dist/scripts/icons/icon_chevron_left'
import IconArrowRight from '@veneer/core/dist/scripts/icons/icon_chevron_right'
import styled, { css } from 'styled-components'
import { ANDROID, IOS, MAC } from '../../jweb/constants'

export const TilesSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding-left: ${({ theme: { platform } }) =>
    [ANDROID, IOS, MAC].includes(platform) ? '16px' : ''};
`
export const TileWrapper = styled.div`
  width: ${(props) => props.tilesWidth}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  padding-${({ theme: { dir } }) => (dir === 'rtl' ? 'left' : 'right')}: 1.6rem;
`

export const TilesSectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ isCountDisplayed }) =>
    isCountDisplayed === true ? 'start' : 'space-between'};
  width: 100%;
  align-items: center;
`

export const TilesSectionBody = styled.div`
  display: flex;
  flex-direction: ${({ theme: { dir }, shouldScroll }) =>
    dir === 'rtl' && shouldScroll ? 'row-reverse' : 'row'};
  justify-content: space-between;
  width: 100%;
  margin-top: 0.8rem;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const TilesSectionTitle = styled.h1`
  ${({ theme: { platform } }) =>
    [ANDROID, IOS, MAC].includes(platform)
      ? `
      font-size: 1.8rem;
      line-height: 2.4rem;`
      : `
      font-size: 2.4rem;
      line-height: 3.2rem;
  `};
  font-weight: normal;
  margin: 0;
  color: ${({ theme: { styles } }) => styles.colorForegroundMedium};
`

export const StyledScrollBooster = styled.div`
  display: flex;
  flex-direction: row;
`

const styledArrow = css`
  position: absolute;
  top: 45%;
  z-index: 1010;
  background-color: #f2f8fb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0.6rem;
  border-radius: 0.8rem;
  cursor: pointer;
  && {
    height: 3.6rem;
    width: 3.6rem;
    fill: ${({ theme: { styles } }) => styles.colorScrollButton};
  }

  &:hover {
    background-color: #ebf9ff;
  }
`

export const StyledIconArrowCircleLeft = styled(IconArrowLeft)`
  ${styledArrow}
  left: 2.5%;
`

export const StyledIconArrowCircleRight = styled(IconArrowRight)`
  ${styledArrow}
  right: 2.5%;
`

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme: { styles } }) => styles.colorForegroundLink};
  font-size: 1.6rem;
  line-height: 2rem;
  cursor: pointer;
  margin: 0.2rem;
  &:focus {
    outline: 0.2rem solid #027aae;
    border-radius: 0.8rem;
  }
`

export const Count = styled.div`
  font-size: 1.8rem;
  line-height: 2rem;
  margin: 0 0 0 4px;
  color: ${({ theme: { styles } }) => styles.colorForegroundLight};
`
