import React, { useRef, KeyboardEvent } from 'react'
import Tooltip from '@veneer/core/dist/scripts/tooltip'
import {
  StyledTitle,
  StyledDescription,
  StyledIconLightBulb,
  PromotionCard,
  ImageContainer,
  TitleContainer,
  PromotionTag
} from './PromoTile.styles'
import { PromotionTileProps } from '../../types/welcomepage'
import { getComputedfontSize } from '../../utils/helpers'
import { useFocalPointImage } from '../../hooks/useFocalPointImage'

const PromoTile: React.FC<PromotionTileProps> = ({
  id,
  image,
  description,
  title,
  hasTitleTag,
  onClick
}: PromotionTileProps) => {
  const descriptionRef = useRef<HTMLDivElement | null>(null)
  useFocalPointImage(`${id}-image`, image)
  return (
    <PromotionCard
      id={id}
      data-testid={id}
      onClick={onClick}
      tabIndex={0}
      aria-label={description}
      onKeyPress={(e: KeyboardEvent<HTMLImageElement>) => {
        if (e.key === 'Enter') {
          onClick(e)
        }
      }}
    >
      <ImageContainer>
        <img src={image} id={`${id}-image`} data-testid={`${id}-image`}></img>
        {hasTitleTag && (
          <Tooltip
            content="Tips and tutorial"
            id={`${id}-tooltip`}
            data-testid={`${id}-tooltip`}
          >
            <PromotionTag>
              <StyledIconLightBulb filled />
            </PromotionTag>
          </Tooltip>
        )}
      </ImageContainer>
      <TitleContainer>
        <StyledTitle id={`${id}-title`} data-testid={`${id}-title`}>
          {title.substring(0, 25)}
        </StyledTitle>
        <StyledDescription
          id={`${id}-description`}
          data-testid={`${id}-description`}
          ref={descriptionRef}
          computedFontSize={getComputedfontSize(descriptionRef)}
          dangerouslySetInnerHTML={{
            __html: `${description}`
          }}
        ></StyledDescription>
      </TitleContainer>
    </PromotionCard>
  )
}

export default PromoTile
