import { sendEvent, launchURL } from './plugins/serviceRouting'

/**
 * Sends an event to the Native App to trigger the DSP workflow.
 * @param {string} jumpId Jump ID for the UDL
 */
export const sendShowDSPEvent = (jumpId) => {
  const data = {
    JumpID: jumpId
  }
  sendEvent('ShowDSP', data)
}

/**
 * Sends an event to the Native App to trigger the Account Creation workflow.
 * @param {string} jumpId Jump ID for the UDL
 */
export const sendShowCreateAccountEvent = (jumpId) => {
  const data = {
    JumpID: jumpId
  }
  sendEvent('ShowCreateAccount', data)
}

/**
 * Sends an event to the Native App to trigger the Sign In workflow.
 * @param {*} jumpId Jump ID for the UDL
 */
export const sendShowSignInEvent = (jumpId) => {
  const data = {
    JumpID: jumpId
  }
  sendEvent('ShowSignIn', data)
}

/**
 * Sends an event to the Native App to trigger the Hp+ second chance workflow.
 * @param {string} jumpId Jump ID for the UDL
 */
export const sendShowHPPlusConversionEvent = (eventData) => {
  const data = {
    ...eventData
  }
  sendEvent('ShowHPPlusConversion', data)
}

/**
 * Sends an event to the Native App to launch URL.
 * @param {string} url Url to Load
 */
export const sendLaunchUrlEvent = (url, nativeNavBar) => {
  launchURL(url, nativeNavBar)
}

/**
 * Sends an event to the native app to launch a JWEB app tile.
 */
export const sendJwebLaunchEvent = (data = {}) => {
  sendEvent('jwebServiceRouterLaunched', data)
}
