import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import ScrollBooster from 'scrollbooster'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import {
  StyledIconArrowCircleLeft,
  StyledIconArrowCircleRight,
  StyledScrollBooster,
  TilesSectionContainer,
  TilesSectionBody,
  TilesSectionHeader,
  TilesSectionTitle,
  TileWrapper,
  StyledLink,
  Count
} from './CarouselWidgetA.styles'
import { useTheme } from 'styled-components'
import { CarouselWidgetAProps } from '../../types/welcomepage'
import {
  trackTileViewSimpleUIEvent,
  trackScrollClickedSimpleUiEvent
} from '../../utils/simpleUIAnalytics'
import {
  getTilesSeenFromStorage,
  setTilesSeenToStorage
} from '../../utils/analytics'
import { carouselDirectionEnum } from '../../utils/constants'
import { usePublishHeight } from '../../hooks/usePublishHeight'

const CarouselWidgetA: React.FC<CarouselWidgetAProps> = ({
  title,
  TileTemplate,
  isShowAllDisplayed,
  showAllText,
  isSwipeEnabled,
  tileList,
  onShowAllClicked,
  tilesCount,
  tileWidth,
  includedTileType,
  isDragAllowed,
  onTileClick,
  onTileDismiss,
  isCountDisplayed,
  onTileSnooze
}: CarouselWidgetAProps) => {
  const slider = useRef<any>(null)
  const scrollBoosterRef = useRef<any>(null)
  const isPointerUp = useRef<boolean>(false)
  const prevScrollx = useRef<number>(0)
  const [shouldScroll, setShouldScroll] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [eachTileWidth, setEachTileWidth] = useState(0)
  const [shouldShowLeftScrollArrow, setShouldShowLeftScrollArrow] =
    useState(false)
  const [shouldShowRightScrollArrow, setShouldShowRightScrollArrow] =
    useState(false)
  const theme = useTheme()
  const { dir } = theme
  const directionReverser = carouselDirectionEnum[dir]
  const [enableSwipe, setEnableSwipe] = useState(isSwipeEnabled)
  const { sectionRef, publishHeight } = usePublishHeight(
    includedTileType,
    theme
  )
  const scrolledManually = useRef<boolean>(true)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResize)
    }
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  useEffect(() => {
    // Set each tile width on load and check if scroll should be enabled
    const sliderWidth = slider.current?.offsetWidth
    const newTileWidth = tilesCount ? sliderWidth / tilesCount : tileWidth
    setEachTileWidth(newTileWidth)
    shouldShowScroll()
  }, [tileList, slider?.current, tilesCount, tileWidth])

  useEffect(() => {
    resetScrollBooster()
  }, [shouldScroll])

  useEffect(() => {
    const onWheelScroll = slider.current
    if (onWheelScroll) {
      const wheelListener = (e: any) => {
        e.preventDefault()
        onWheelScroll.scrollTo({
          left: onWheelScroll.scrollLeft + e.deltaY * 10,
          behavior: 'smooth'
        })
      }
      onWheelScroll.addEventListener('wheel', wheelListener)
      return () => onWheelScroll.removeEventListener('wheel', wheelListener)
    }
  }, [])

  const resetScrollBooster = () => {
    if (shouldScroll && !scrollBoosterRef.current) {
      // Initiate scroll booster if the section should scroll
      initiateScrollBooster()
    } else if (!shouldScroll && scrollBoosterRef.current) {
      slider.current.children &&
        (slider.current.children[0].style.transform = 'initial')
      scrollBoosterRef.current?.updateMetrics()
      scrollBoosterRef.current?.destroy()
      scrollBoosterRef.current = null
      slider.current.removeAttribute('data-boosted')
    }
  }

  const shouldShowScroll = () => {
    if (slider && slider.current && (isSwipeEnabled || isDragAllowed)) {
      const sliderWidth = slider.current.offsetWidth
      const sliderContentWidth =
        slider.current.children && slider.current.children[0].offsetWidth
      if (tilesCount && tilesCount === tileList?.length) {
        setShouldScroll(false)
      } else {
        setShouldScroll(sliderWidth < sliderContentWidth)
      }
    } else if (!isSwipeEnabled && !isDragAllowed) {
      setShouldScroll(false)
    }
  }

  useLayoutEffect(() => {
    if (tilesCount) {
      const newTileWidth = tilesCount
        ? slider.current.offsetWidth / tilesCount
        : tileWidth
      if (
        scrollBoosterRef.current &&
        scrollBoosterRef.current.position.x !== 0 &&
        eachTileWidth &&
        eachTileWidth !== newTileWidth
      ) {
        const tilesScrolled = Math.round(
          Math.abs(scrollBoosterRef.current.targetPosition.x) / eachTileWidth
        )
        if (tilesScrolled) {
          scrollBoosterRef.current.scrollTo({
            x: newTileWidth * tilesScrolled * directionReverser,
            y: 0
          })
        }
      }
      setEachTileWidth(newTileWidth)
    }
  }, [tilesCount, slider?.current?.offsetWidth])

  useEffect(() => {
    // on load, track tiles shown to user.
    // tracking here is done only for ltr layout and rtl layout when scrolling is not enabled.
    // tracking for RTL layout with scrolling is done in onupdate function of scroll booster
    setTimeout(() => {
      if (dir === 'ltr' || (dir === 'rtl' && !shouldScroll)) {
        trackTilesSeen(true)
      }
      publishHeight()
    }, 100)
  }, [])

  const initiateScrollBooster = () => {
    if (slider?.current) {
      slider.current.setAttribute('data-boosted', true)
      scrollBoosterRef.current = new ScrollBooster({
        scrollMode: 'transform',
        direction: 'horizontal',
        viewport: slider.current,
        lockScrollOnDragDirection: 'horizontal',
        dragDirectionTolerance: 20,
        shouldScroll: () => {
          return isDragAllowed
        },
        onUpdate: (state) => {
          if (!state.isMoving) {
            if (!scrolledManually.current && dir === 'rtl') {
              // track tiles seen on load of tiles in rtl layout with scrolling
              trackTilesSeen()
            }
            scrolledManually.current = false
          }

          // scrolling takes couple of seconds to completely come to a stop.
          // therefore checking tiles seen, when scrolling has slowed down,
          // rather than waiting for scrolling to stop
          const scrollDistance = Math.abs(
            prevScrollx.current - state.position.x
          )
          prevScrollx.current = state.position.x
          if (isPointerUp.current && scrollDistance < 2 && scrollDistance > 1) {
            trackTilesSeen()
          }

          // Determine if left and right scroll arrows should be displayed
          const totalWidth = eachTileWidth * tileList?.length
          setShouldShowRightScrollArrow(
            totalWidth - (slider.current.offsetWidth + state.position.x) > 10
          )
          setShouldShowLeftScrollArrow(state.position.x > 14)
        },
        onPointerDown: () => {
          isPointerUp.current = false
        },
        onPointerUp: () => {
          isPointerUp.current = true
        },
        onpointermove: () => {
          if (isDragAllowed) {
            scrolledManually.current = true
          }
        }
      })
      if (dir === 'rtl') {
        // set scroll position to end for RTL layouts
        scrollBoosterRef.current.setPosition({
          x: slider.current.children[0].clientWidth,
          y: 0
        })
      }
    }
  }

  const trackTilesSeen = (onLoad?: boolean) => {
    if (onTileSeen) {
      const tilesSeen = getTilesSeen()
      onTileSeen(tilesSeen, !!onLoad, includedTileType)
    }
  }

  const getTilesSeen = () => {
    const seenTiles: Array<any> = []
    if (slider && slider.current?.children) {
      const sliderBounds = slider.current.getBoundingClientRect()
      Array.from(slider.current.children[0].children).forEach((card, index) => {
        const cardBounds = (
          card as HTMLElement
        ).children[0].getBoundingClientRect()
        if (
          cardBounds.left >= sliderBounds.left &&
          cardBounds.right <= sliderBounds.right
        ) {
          tileList[index].position = `${index + 1}of${tileList.length}`
          seenTiles.push(tileList[index])
        }
      })
    } else {
      tileList.forEach((t, index, thisArray) => {
        thisArray[index].position = `${index + 1}of${thisArray.length}`
      })
      seenTiles.push(...tileList)
    }

    return seenTiles
  }

  const onTileSeen = (tiles: any[], isOnLoad: boolean, controlType: string) => {
    if (tiles && tiles.length) {
      if (!isOnLoad) {
        const alreadySeenTiles = getTilesSeenFromStorage()
        const newSeenTiles = tiles.filter(
          (tile) => !alreadySeenTiles.includes(tile.id)
        )
        if (newSeenTiles.length) {
          trackTileViewSimpleUIEvent(newSeenTiles, controlType)
          setTilesSeenToStorage(newSeenTiles)
        }
      } else {
        trackTileViewSimpleUIEvent(tiles, controlType)
        setTilesSeenToStorage(tiles)
      }
    }
  }

  const onClick = (tile: any, tileType: string, index: number) => {
    const alreadySeenTiles = getTilesSeenFromStorage()
    if (alreadySeenTiles.indexOf(tile.id) === -1) {
      trackTileViewSimpleUIEvent([tile], tileType)
      setTilesSeenToStorage([tile])
    }
    onTileClick(tile, tileType, index)
  }

  const scroll = (direction: string) => {
    if (scrollBoosterRef.current) {
      let currentScroll = scrollBoosterRef.current.position.x * -1
      if (direction === 'left') {
        currentScroll -= eachTileWidth
      } else if (direction === 'right') {
        currentScroll += eachTileWidth
      }
      const sliderContentWidth = slider.current.children[0].offsetWidth
      const differencewidth = sliderContentWidth - slider.current.offsetWidth
      if (currentScroll < 0) {
        currentScroll = 0
        setShouldShowLeftScrollArrow(false)
      }
      if (currentScroll > differencewidth) {
        currentScroll = differencewidth
        setShouldShowRightScrollArrow(false)
      }

      scrollBoosterRef.current.scrollTo({
        x: currentScroll,
        y: 0
      })
      scrolledManually.current = true
    }
  }

  const onResize = () => {
    publishHeight()
    shouldShowScroll()
    trackTilesSeen()
  }

  return (
    <TilesSectionContainer ref={sectionRef}>
      <TilesSectionHeader isCountDisplayed={isCountDisplayed}>
        <TilesSectionTitle data-testid="title">{title}</TilesSectionTitle>
        <Count>{!!isCountDisplayed && `(${tileList.length})`}</Count>
        {isShowAllDisplayed && (
          <StyledLink
            id="see_all_button"
            data-testid="see_all_button"
            onClick={(e) => {
              onShowAllClicked(e)
            }}
            tabIndex="0"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onShowAllClicked(e)
              }
            }}
          >
            {showAllText}
          </StyledLink>
        )}
      </TilesSectionHeader>
      {isHovered && shouldShowLeftScrollArrow && shouldScroll && (
        <StyledIconArrowCircleLeft
          size={24}
          id="left_arrow"
          data-testid="left_arrow"
          onClick={() => {
            scroll('left')
            trackScrollClickedSimpleUiEvent('LeftScroll', includedTileType)
          }}
          onMouseEnter={() => {
            setEnableSwipe(true)
            enableSwipe && setIsHovered(true)
          }}
          onMouseLeave={() => enableSwipe && setIsHovered(false)}
        />
      )}
      {isHovered && shouldShowRightScrollArrow && shouldScroll && (
        <StyledIconArrowCircleRight
          size={24}
          id="right_arrow"
          data-testid="right_arrow"
          onClick={() => {
            scroll('right')
            trackScrollClickedSimpleUiEvent('RightScroll', includedTileType)
          }}
          onMouseEnter={() => {
            setEnableSwipe(true)
            enableSwipe && setIsHovered(true)
          }}
          onMouseLeave={() => enableSwipe && setIsHovered(false)}
        />
      )}
      <TilesSectionBody
        ref={slider}
        data-testid="tiles-section-body"
        shouldScroll={shouldScroll}
        onMouseEnter={() => enableSwipe && setIsHovered(true)}
        onMouseLeave={() => enableSwipe && setIsHovered(false)}
      >
        <StyledScrollBooster role="group">
          <TransitionGroup component={null}>
            {slider?.current?.offsetWidth &&
              tileList?.map((tile, index) => {
                if (
                  enableSwipe ||
                  isDragAllowed ||
                  (!enableSwipe && !isDragAllowed && index < tilesCount)
                ) {
                  return (
                    <CSSTransition
                      key={index}
                      timeout={500}
                      classNames="carousel"
                      exit={false}
                    >
                      <TileWrapper tilesWidth={eachTileWidth}>
                        <TileTemplate
                          {...tile}
                          onClick={() => onClick(tile, includedTileType, index)}
                          onDismiss={() =>
                            onTileDismiss(tile, includedTileType)
                          }
                          onSnooze={() => onTileSnooze(tile, includedTileType)}
                          onContextMenuOpen={setEnableSwipe}
                          dismissable={tile.dismissible}
                        />
                      </TileWrapper>
                    </CSSTransition>
                  )
                }
              })}
          </TransitionGroup>
        </StyledScrollBooster>
      </TilesSectionBody>
    </TilesSectionContainer>
  )
}

export default CarouselWidgetA
