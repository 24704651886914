import {
  LARGE_SCREEN_WIDTH_PX,
  MEDIUM_SCREEN_WIDTH_PX,
  SMALL_SCREEN_WIDTH_PX
} from '../utils/constants'

export default function computeTilesCount(screenWidth) {
  switch (true) {
    case screenWidth < SMALL_SCREEN_WIDTH_PX:
      return {
        ActionTile: 2,
        PromotionTile: 2,
        NextStepTile: 1
      }
    case screenWidth >= SMALL_SCREEN_WIDTH_PX &&
      screenWidth < MEDIUM_SCREEN_WIDTH_PX:
      return {
        ActionTile: 2,
        PromotionTile: 3,
        NextStepTile: 2
      }
    case screenWidth >= MEDIUM_SCREEN_WIDTH_PX &&
      screenWidth < LARGE_SCREEN_WIDTH_PX:
      return {
        ActionTile: 3,
        PromotionTile: 4,
        NextStepTile: 3
      }
    case screenWidth >= LARGE_SCREEN_WIDTH_PX:
      return {
        ActionTile: 3,
        PromotionTile: 4,
        NextStepTile: 3
      }
  }
}
