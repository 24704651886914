import { openURL, canOpenURL } from '@hp-af/linking'

export const openUrl = async (url) => {
  try {
    const canOpen = await canOpenURL(url)
    /* istanbul ignore else */
    if (canOpen) {
      await openURL(url)
    }
  } catch ({ message }) {
    console.log('@hp-af/linking', 'openURL', `${message}`)
  }
}
