// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/fonts/HP-simplified-Regular.woff";
import ___CSS_LOADER_URL_IMPORT_1___ from "../assets/fonts/HP-simplified-Light.woff";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n@font-face {\n  font-display: swap;\n  font-family: 'HP Simplified Regular';\n  font-weight: normal;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff');\n}\n\n@font-face {\n  font-display: swap;\n  font-family: 'HP Simplified Light';\n  font-weight: 300;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');\n}\n", "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,oCAAoC;EACpC,mBAAmB;EACnB,2DAAqE;AACvE;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;EAClC,gBAAgB;EAChB,2DAAmE;AACrE","sourcesContent":["\n@font-face {\n  font-display: swap;\n  font-family: 'HP Simplified Regular';\n  font-weight: normal;\n  src: url('../assets/fonts/HP-simplified-Regular.woff') format('woff');\n}\n\n@font-face {\n  font-display: swap;\n  font-family: 'HP Simplified Light';\n  font-weight: 300;\n  src: url('../assets/fonts/HP-simplified-Light.woff') format('woff');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
