import React, { useRef, KeyboardEvent } from 'react'
import { getComputedfontSize } from '../../utils/helpers'
import { ActionTileProps } from '../../types/welcomepage'
import {
  Card,
  CardImageContainer,
  CardTitle,
  CardSubTitle,
  StyledIconX
} from './ActionTile.styles'

const ActionTile: React.FC<ActionTileProps> = ({
  id,
  title,
  subTitle,
  image,
  dismissable,
  onDismiss,
  onClick
}: ActionTileProps) => {
  const titleRef = useRef<HTMLDivElement | null>(null)
  const subTitleRef = useRef<HTMLDivElement | null>(null)

  return (
    <Card
      id={id}
      data-testid={id}
      onClick={onClick}
      tabIndex="0"
      onKeyPress={(e: KeyboardEvent<HTMLImageElement>) => {
        if (e.key === 'Enter') {
          onClick(e)
        }
      }}
    >
      {dismissable && (
        <StyledIconX
          id={`${id}_close_button`}
          data-testid={`${id}_close_button`}
          onClick={onDismiss}
        ></StyledIconX>
      )}
      <CardImageContainer>
        <img src={image} id={`${id}-image`} data-testid={`${id}-image`}></img>
        <CardTitle
          id={`${id}-title`}
          data-testid={`${id}-title`}
          ref={titleRef}
          computedFontSize={getComputedfontSize(titleRef)}
        >
          {title}
        </CardTitle>
      </CardImageContainer>
      <CardSubTitle
        id={`${id}-subtitle`}
        data-testid={`${id}-subtitle`}
        ref={subTitleRef}
        computedFontSize={getComputedfontSize(subTitleRef)}
      >
        {subTitle}
      </CardSubTitle>
    </Card>
  )
}

export default ActionTile
