import { useRef } from 'react'
import { publishEvent } from '../jweb/plugins/eventServiceLite'
import { PAGE_HEIGHT_EVENT } from '../utils/constants.js'

export const usePublishHeight = (includedTileType, { platform }) => {
  const sectionRef = useRef()
  const sectionHeightRef = useRef(0)

  const publishHeight = () => {
    let currentHeight = sectionRef.current?.offsetHeight
    if (platform === 'windows') {
      // Adding 0.03 offset to avoid scroll bar in native windows app
      currentHeight = currentHeight + currentHeight * 0.03
    }
    if (currentHeight !== sectionHeightRef.current) {
      sectionHeightRef.current = currentHeight
      publishEvent(PAGE_HEIGHT_EVENT, {
        type: includedTileType,
        height: currentHeight
      })
    }
  }

  return { sectionRef, publishHeight }
}
