import { jWebReady } from '@jarvis/jweb-core'

export const getHostLocaleDetails = async () => {
  try {
    const jweb = await jWebReady
    if (jweb && jweb.isNative) {
      const { LocalizationJPlugin } = jweb.Plugins
      if (LocalizationJPlugin) {
        const locale = (await LocalizationJPlugin.getCurrentLocaleCEC()).result
        const language = locale.split('-')[0]
        const country = (await LocalizationJPlugin.get2LetterCountry()).result
        return {
          locale: locale,
          language,
          appCountry: country
        }
      }
    }
    return {}
  } catch (error) {
    console.error(`Localization plugin not available`)

    return {}
  }
}
