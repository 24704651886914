import { useEffect, useState } from 'react'
import computeTilesCount from '../configs/screenWidthBreakPoints'

export const useTilesCount = () => {
  // Setting initial state to tile count when screen size is between 641 and 1008px
  // Hoping majority of users would fall into this screen size
  const [tilesCount, setTilesCount] = useState({
    ActionTile: 2,
    PromotionTile: 3,
    NextStepTile: 2
  })

  useEffect(() => {
    const updateSize = () => {
      setTilesCount(computeTilesCount(window.innerWidth))
    }
    updateSize()
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return { ...tilesCount }
}
