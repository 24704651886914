import React, { useRef, useState } from 'react'
import {
  Card,
  Title,
  SubTitle,
  CardImage,
  CardImagePlaceholder,
  CardImageContainer,
  StyledIconEllipsis,
  ContextMenu
} from './NextStepsTile.styles'
import { getComputedfontSize } from '../../utils/helpers'
import { SNOOZE, DISMISS } from '../../utils/constants'

const NextStepsTile = ({
  id,
  title,
  subTitle,
  image,
  dismissable,
  onClick,
  onDismiss,
  onSnooze,
  onContextMenuOpen
}) => {
  const subTitleRef = useRef()
  const titleRef = useRef()
  const [isContextualMenuOpen, setIsContextualMenuOpen] = useState(false)

  const DoAction = (value) => {
    switch (value) {
      case 1:
        onSnooze()
        break
      case 2:
        onDismiss()
        break
    }
  }

  return (
    <Card
      id={`${id}`}
      data-testid={`${id}_card`}
      onClick={onClick}
      tabIndex="0"
      onKeyPress={(e) => {
        /* istanbul ignore else */
        if (e.key === 'Enter') {
          onClick()
        }
      }}
    >
      {dismissable && (
        <ContextMenu
          placement="bottom-end"
          options={[
            { value: 1, label: SNOOZE, 'data-testid': `${id}_snooze_button` },
            {
              value: 2,
              label: DISMISS,
              disabled: !dismissable,
              'data-testid': `${id}_close_button`
            }
          ]}
          onClick={(e, option) => {
            DoAction(option.value)
          }}
          onClose={() => setIsContextualMenuOpen(false)}
        >
          <StyledIconEllipsis
            id={`${id}_ellipsis`}
            data-testid={`${id}_ellipsis`}
            aria-label={`${id} ellipsis`}
            size={30}
            onMouseOver={(e) => {
              e.stopPropagation()
              onContextMenuOpen(false)
            }}
            onClick={(e) => {
              e.stopPropagation()
              onContextMenuOpen(false)
            }}
            isContextualMenuOpen={isContextualMenuOpen}
          />
        </ContextMenu>
      )}
      <CardImageContainer>
        {image ? (
          <CardImage
            src={image}
            id={`${id}-image`}
            data-testid={`${id}-image`}
            height="24"
          />
        ) : (
          <CardImagePlaceholder
            id={`${id}-image`}
            data-testid={`${id}-image-alt`}
          ></CardImagePlaceholder>
        )}
        <Title
          id={`${id}_title`}
          ref={titleRef}
          computedFontSize={getComputedfontSize(titleRef)}
        >
          {title}
        </Title>
      </CardImageContainer>
      <SubTitle
        id={`${id}_subTitle`}
        ref={subTitleRef}
        computedFontSize={getComputedfontSize(subTitleRef)}
      >
        {subTitle}
      </SubTitle>
    </Card>
  )
}
export default NextStepsTile
