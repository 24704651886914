import { ANDROID, IOS } from '../constants'
import { jWebReady } from '@jarvis/jweb-core'

/**
 * Request for Device Information from the Native App.
 * Payload {
  "diskFree": 12228108288,
  "appVersion": "1.0.2",
  "appBuild": "123",
  "operatingSystem": "ios",
  "osVersion": "11.2",
  "platform": "ios",
  "memUsed": 93851648,
  "jwebviewVersion": "0.1.11",
  "batteryLevel": 1,
  "isCharging": false
  "diskTotal": 499054952448,
  "model": "iPhone",
  "manufacturer": "Apple",
  "uuid": "84AE7AA1-7000-4696-8A74-4FD588A4A5C7",
  "isVirtual":true,
  "locale": {
    "languageTag": "en-US",
    "language": "en",
    "country": "US"
  }
 * @returns {Promise} Device Information
 */
export const getDeviceInformation = async () => {
  try {
    const jweb = await jWebReady

    if (jweb && jweb.isNative) {
      const { Device } = jweb.Plugins
      return await Device.getInfo()
    }
    return { platform: jweb.platform }
  } catch (error) {
    console.error(`DEVICE INFO ERROR: ${error}`)
    return {}
  }
}

/**
 * Checks if the host is mobile platform.
 * @returns A flag determining if the host is mobile platform (Android || Ios)
 */

export const isMobileHost = async () => {
  try {
    const jweb = await jWebReady
    if (jweb?.isNative) {
      return jweb.platform === ANDROID || jweb.platform === IOS
    }
    return false
  } catch (error) {
    console.error(`Jweb platform ERROR: ${error}`)
    return false
  }
}

/**
 * Return host platform.
 * @returns host platform
 */
export const getHost = async () => {
  try {
    const jweb = await jWebReady
    if (jweb) {
      return jweb.platform
    }
    return null
  } catch (error) {
    console.error(`Jweb platform ERROR: ${error}`)
    return null
  }
}
