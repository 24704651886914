import IconX from '@veneer/core/dist/scripts/icons/icon_x'
import styled from 'styled-components'

const textDirection = (dir) => (dir === 'rtl' ? 'right' : 'left')

export const Card = styled.div`
  height: 14.8rem;
  border-radius: 0.8rem;
  background-color: ${({ theme: { styles } }) =>
    styles.colorContainerBackground};
  padding: 1.6rem;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  white-space: normal;
  cursor: pointer;
  margin: 0.2rem;
`
export const CardImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 5.6rem;
`

export const StyledIconX = styled(IconX)`
  && {
    color: grey;
    position: absolute;
    top: 1.6rem;
    ${({ theme: { dir } }) =>
      dir === 'rtl' ? 'left: 1.6rem' : 'right: 1.6rem'};
    width: 2rem;
    height: 2rem;
  }
`

export const CardTitle = styled.h4`
  color: ${({ theme: { styles } }) => styles.colorTileTitle};
  font-size: 1.6rem;
  line-height: 2rem;
  margin: 0;
  text-align: ${({ theme: { dir } }) => textDirection(dir)};
  letter-spacing: 0.02em;
  font-weight: normal;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ computedFontSize }) =>
    computedFontSize > 16 ? 1 : 2};
`

export const CardSubTitle = styled.h5`
  font-size: 1.2rem;
  color: ${({ theme: { styles } }) => styles.colorTilesubTitle};
  margin: 0;
  text-align: ${({ theme: { dir } }) => textDirection(dir)};
  line-height: 1.6rem;
  letter-spacing: 0.04em;
  font-weight: normal;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ computedFontSize }) =>
    computedFontSize > 16 ? 2 : 4};
`
