import { Focus, FocusedImage } from '@image-focus'
import { useEffect } from 'react'

export const useFocalPointImage = (elementId, imageUrl) => {
  useEffect(() => {
    const searchParams = new URLSearchParams(imageUrl)
    const focalPointX = searchParams.get('focalpointx')
    const focalPointY = searchParams.get('focalpointy')
    if (imageUrl && focalPointX !== null && focalPointY !== null) {
      const imgElement = document.getElementById(elementId) as HTMLImageElement
      const focalPoint: Focus = {
        x: searchParams.get('focalpointx')
          ? parseFloat(searchParams.get('focalpointx'))
          : 0,
        y: searchParams.get('focalpointy')
          ? parseFloat(searchParams.get('focalpointy'))
          : 0
      }

      if (imgElement !== null) {
        new FocusedImage(imgElement, {
          focus: focalPoint
        })
      }
    }
  }, [elementId, imageUrl])
}
