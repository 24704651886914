import styled from 'styled-components'
import {
  LARGE_SCREEN_WIDTH_PX,
  MEDIUM_SCREEN_WIDTH_PX
} from '../../utils/constants'

export const InterstitialSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${({ theme: { styles } }) =>
    styles.colorContainerBackground};
  color: ${({ theme: { styles } }) => styles.colorForeground};
  min-height: 100vh;
  @media screen and (min-width: ${MEDIUM_SCREEN_WIDTH_PX}px) {
    flex-direction: row;
  }
`

export const InterstitialHeadline = styled.h1`
  margin: 0;
  ${({ theme: { mobileHost } }) =>
    mobileHost
      ? `
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.4rem;
  `
      : `
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 3.2rem;
  `}
`

export const InterstitialListItems = styled.ul`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  list-style: disc;
  margin: 0px 0px 24px 25px;
`
export const InterstitialDescription = styled.p`
  ${({ extraWhiteSpace, theme: { mobileHost } }) =>
    mobileHost
      ? `
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2rem;
      margin-top: 1.6rem;
      margin-bottom: ${extraWhiteSpace ? '0.8rem' : '1.6rem'};
      `
      : `
      font-weight: 300;
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-top: 0.8rem;
      margin-bottom: 2.4rem;
      `}
`
export const InterstitialImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

export const InterstitialRightContainer = styled.div`
  display: flex;
  justify-content: top;
  flex-direction: column;
  flex-grow: 1;
  align-items: start;
  text-align: left;
  padding: 4rem;
  @media screen and (min-width: ${MEDIUM_SCREEN_WIDTH_PX}px) {
    padding: 0 8rem;
    & div:first-child {
      max-width: 100%;
    }
    justify-content: center;
    align-items: ${({ textBoxVariation }) =>
      textBoxVariation === 'Left' ? 'flex-start' : 'center'};
    text-align: ${({ textBoxVariation, theme: { dir } }) =>
      textBoxVariation === 'Left'
        ? dir === 'rtl'
          ? 'right'
          : 'left'
        : 'left'};
  }
`

export const InterstitialButtons = styled.div`
  display: flex;
  margin-bottom: ${({ theme: { mobileHost } }) =>
    mobileHost ? '3.3rem' : '3rem'};
  flex-direction: ${({ theme: { mobileHost } }) => mobileHost && 'column'};

  & button:not(:first-of-type) {
    margin-right: ${({ theme: { dir, mobileHost } }) =>
      dir === 'rtl' && !mobileHost && '12px'};
  }
  & > button:nth-child(1) {
    margin-bottom: ${({ theme: { mobileHost } }) => mobileHost && '12px'};
  }
  & > button:nth-child(1) {
    margin-right: ${({ theme: { dir, mobileHost } }) =>
      dir === 'ltr' && !mobileHost && '12px'};
  }
`

export const ImageContainer = styled.div`
  flex-shrink: 0;
  width: 50%;
  height: 100vh;
  position: relative !important;
  min-width: 50% !important;
  @media screen and (min-width: ${LARGE_SCREEN_WIDTH_PX}px) {
    width: 50%;
  }
  @media screen and (max-width: ${MEDIUM_SCREEN_WIDTH_PX}px) {
    width: 0;
    height: 0;
    display: none !important;
  }
`
export const MobileImageContainer = styled.div`
  height: ${({ theme: { mobileHost } }) =>
    mobileHost ? '20.5rem' : '23.9rem'};
  width: 100%;
  flex-shrink: 0;
  @media screen and (min-width: ${MEDIUM_SCREEN_WIDTH_PX}px) {
    width: 0;
    height: 0;
    display: none !important;
  }
`

export const InterstitialIcon = styled.div`
  border-radius: 20%;
  height: 7rem;
  width: 7rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: ${(props) => props.icon};
  background-color: rgb(255, 255, 255);
  border: 0px;
  max-height: ${({ theme: { mobileHost } }) => mobileHost && '4.4rem'};
  margin-bottom: ${({ theme: { mobileHost } }) =>
    mobileHost ? '1.6rem' : '0'};
`

export const DisclaimerHeadline = styled.h1`
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin: 0;
`

export const DisclaimerDescription = styled.p`
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-top: 0.4rem;
  margin-bottom: 3rem;
  color: ${({ theme: { styles } }) => styles.colorTilesubTitle};
`

export const FooterDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ theme: { mobileHost } }) =>
    mobileHost ? '2.5rem' : '56px'};
`
