import { openUrl } from './plugins/linkingJPlugin'
import { closeServiceInstance, launchURL } from './plugins/serviceRouting'
import { HPX_INTERSTITIAL_TYPE } from '../utils/constants'
import {
  CLOSE,
  DEEPLINK,
  INTERSTITIAL,
  URL,
  DSP,
  CREATEACCOUNT,
  SIGNIN,
  CALLTYPE,
  CALLVALUE,
  HPPLUSCONVERSION,
  WINDOWS
} from './constants'
import {
  sendShowDSPEvent,
  sendShowCreateAccountEvent,
  sendShowSignInEvent,
  sendShowHPPlusConversionEvent
} from './events'
import { getCurrentContext, getDeviceInfo } from '../utils/persistData'
import { queryStringFromObject } from '../utils/helpers'

export const triggerEvent = (tile, tileType, mode) => {
  const cta = tile.cta
  const ctaType = cta.cta_type?.toLowerCase()
  const useExternalBrowser = cta?.open_in_external_browser
  let { platform: device } = getDeviceInfo()

  switch (ctaType) {
    case DEEPLINK: {
      const deeplink = cta.target_url_or_deeplink?.href
      openUrl(deeplink)
      break
    }
    case URL: {
      let url = cta.target_url_or_deeplink?.href
      //Adding protocol incase its missing in URL
      if (url.indexOf('https://') !== 0 && url.indexOf('http://') !== 0) {
        url = `https://${url}`
      }
      if (WINDOWS) {
        openUrl(url)
      } else {
        launchURL(window.encodeURI(url), true, useExternalBrowser)
      }
      break
    }
    case INTERSTITIAL: {
      const interstitial = cta.interstitial
      const entryUid = interstitial[0]?.uid
      const contentTypeUid = interstitial[0]?._content_type_uid
      const currentContext = getCurrentContext()
      const contextQueryString = queryStringFromObject(currentContext, true)
      const url = `${window.location.origin}${window.location.pathname}?type=${HPX_INTERSTITIAL_TYPE}&entryUid=${entryUid}&contentTypeUid=${contentTypeUid}&originName=${tile.campaignName}&originType=${tileType}${contextQueryString}&mode=${mode}`

      // launch interstitial on service router
      launchURL(window.encodeURI(url), true)
      break
    }
    case DSP: {
      sendShowDSPEvent(cta.jump_id?.[device])
      break
    }
    case CREATEACCOUNT: {
      sendShowCreateAccountEvent(cta.jump_id?.[device])
      break
    }
    case SIGNIN: {
      sendShowSignInEvent(cta.jump_id?.[device])
      break
    }
    case HPPLUSCONVERSION: {
      const eventData = createHpPlusEventData(cta.jump_id?.[device])
      sendShowHPPlusConversionEvent(eventData)
      break
    }
    case CLOSE: {
      closeServiceInstance()
      break
    }
    default: {
      break
    }
  }

  const createHpPlusEventData = (jumpId) => ({
    features: {
      ShowHPPlusConversion: true
    },
    ucde: {
      uiinfos: {
        callType: CALLTYPE,
        callValue: CALLVALUE
      }
    },
    udl: {
      encrypted: {
        JumpID: jumpId
      }
    }
  })
}
