import { DataCollectionEventNames } from '@jarvis/jweb-core'
import { publishEvent } from './eventService'

import {
  EVENT_SCHEMA_VERSION,
  SIMPLE_UI_CATEGORY,
  SIMPLE_UI_GUN
} from '../constants'

export const sendSimpleUiEvent = (event) => {
  // console.log('dataCollection::sendSimpleUiEvent: %o', event)
  try {
    const date = new Date()
    const cdmEvent = {
      dateTime: date.toISOString(),
      eventDetailType: SIMPLE_UI_GUN,
      eventCategory: SIMPLE_UI_CATEGORY,
      version: EVENT_SCHEMA_VERSION,
      eventDetail: event
    }
    const eventData = {
      events: [cdmEvent]
    }
    publishEvent(DataCollectionEventNames.cdmEvent, eventData)
  } catch (error) {
    console.error('dataCollection::sendSimpleUiEvent: %o', error)
  }
}

export const sendSimpleUiEventList = (eventList) => {
  // console.log('dataCollection::sendSimpleUiEvent: %o', event)
  try {
    const date = new Date().toISOString()
    const events = []
    eventList.forEach((event) => {
      const cdmEvent = {
        dateTime: date,
        eventDetailType: SIMPLE_UI_GUN,
        eventCategory: SIMPLE_UI_CATEGORY,
        version: EVENT_SCHEMA_VERSION,
        eventDetail: event
      }
      events.push(cdmEvent)
    })

    const eventData = {
      events: events
    }
    publishEvent(DataCollectionEventNames.cdmEvent, eventData)
  } catch (error) {
    console.error('dataCollection::sendSimpleUiEvent: %o', error)
  }
}
