import tokens from '@veneer/tokens'

export const themes = {
  light: {
    colorPageBg: tokens.colorGray0,
    colorSectionBackground: tokens.colorGray3,
    colorContainerBackground: tokens.colorWhite,
    colorForeground: tokens.colorGray10,
    colorForegroundLight: tokens.colorGray5,
    colorForegroundMedium: tokens.colorGray7,
    colorForegroundLink: tokens.colorHpBlue6,
    colorScrollButton: tokens.colorHpBlue7,
    colorExpandChevron: tokens.colorGray10,
    colorTileTitle: tokens.colorGray7,
    colorTilesubTitle: tokens.colorGray5,
    colorTileTitleInverted: tokens.colorGray7,
    colorTilesubTitleInverted: tokens.colorGray5,
    colorContainerBackgroundInverted: tokens.colorWhite,
    colorFillerBackground: tokens.colorGray3,
    colorHoverBackground: tokens.colorGray4,
    colorHoverBackgroundFull: tokens.colorHpBlue2,
    colorHoverText: tokens.colorWhite,
    colorActiveBackground: tokens.colorHpBlue0
  },
  dark: {
    colorPageBg: tokens.colorGray10,
    colorSectionBackground: tokens.colorGray7,
    colorContainerBackground: tokens.colorGray9,
    colorForeground: tokens.colorGray0,
    colorForegroundLight: tokens.colorGray5,
    colorForegroundMedium: tokens.colorWhite,
    colorForegroundLink: tokens.colorHpBlue6,
    colorScrollButton: tokens.colorHpBlue7,
    colorExpandChevron: tokens.colorWhite,
    colorTileTitle: tokens.colorGray2,
    colorTilesubTitle: tokens.colorGray4,
    colorTileTitleInverted: tokens.colorGray7,
    colorTilesubTitleInverted: tokens.colorGray5,
    colorContainerBackgroundInverted: tokens.colorWhite,
    colorHoverBackground: tokens.colorHpBlue7,
    colorHoverBackgroundFull: tokens.colorGray4,
    colorHoverText: tokens.colorWhite,
    colorActiveBackground: tokens.colorGray6
  },
  highcontrast: {
    colorPageBg: tokens.colorGray10,
    colorSectionBackground: tokens.colorGray7,
    colorContainerBackground: tokens.colorGray9,
    colorForeground: tokens.colorGray2,
    colorForegroundLight: tokens.colorGray5,
    colorForegroundMedium: tokens.colorWhite,
    colorForegroundLink: tokens.colorHpBlue6,
    colorScrollButton: tokens.colorHpBlue7,
    colorExpandChevron: tokens.colorWhite,
    colorTileTitle: tokens.colorGray2,
    colorTilesubTitle: tokens.colorGray4,
    colorTileTitleInverted: tokens.colorGray7,
    colorTilesubTitleInverted: tokens.colorGray5,
    colorContainerBackgroundInverted: tokens.colorWhite,
    colorHoverBackground: tokens.colorHighContrastGray,
    colorHoverBackgroundFull: tokens.colorHighContrastPink,
    colorHoverText: tokens.colorWhite,
    colorActiveBackground: tokens.colorHighContrastBlue
  }
}
