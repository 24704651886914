import styled from 'styled-components'

export const TilesSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`
export const TileWrapper = styled.div`
  width: ${(props) => props.tilesWidth};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  padding-${({ theme: { dir } }) => (dir === 'rtl' ? 'left' : 'right')}: 1.6rem;
  margin-bottom: 1.6rem;
`

export const TilesSectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

export const TilesSectionBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
  margin-top: 1rem;
  overflow-x: hidden;
  flex-wrap: wrap;
  // row-gap: 1.6rem;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const TilesSectionTitle = styled.h1`
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: normal;
  margin: 0;
  color: ${({ theme: { styles } }) => styles.colorForegroundMedium};
`

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme: { styles } }) => styles.colorForegroundLink};
  font-size: 1.6rem;
  line-height: 2rem;
  cursor: pointer;
`
