import { useEffect, useState } from 'react'
import { checkSignInAndGetUserDetails } from '../jweb/plugins/auth'
import { getDeviceInformation, getHost } from '../jweb/plugins/device'
import { getHostLocaleDetails } from '../jweb/plugins/localizationJPlugin'
import { getHostLocaleDetailsWeb } from '../jweb/web'
import { getValueFromValueStore } from '../jweb/plugins/valueStore'
import {
  HPX_INTERSTITIAL_TYPE,
  VALUE_STORE_ANALYTICS_UUID,
  VALUE_STORE_DEVICEINFO_UUID,
  VALUE_STORE_FEATURES_KEY
} from '../utils/constants'
import {
  setDeviceInfo,
  setFeatureList,
  setUserInfo,
  setMobileHost
} from '../utils/persistData'
import { ANDROID, IOS } from '../jweb/constants'

const setInitialContext = async () => {
  let appIDFromAnalytics, featureList
  let hostLocaleDetails = {}
  const platform = await getHost()

  const contextPromises = [
    checkSignInAndGetUserDetails().catch(() => ({})),
    getDeviceInformation().catch(() => ({}))
  ]

  if (platform === 'windows') {
    contextPromises.push(getHostLocaleDetails().catch(() => ({})))
    appIDFromAnalytics = await getValueFromValueStore(
      VALUE_STORE_ANALYTICS_UUID
    )
    featureList = await getValueFromValueStore(VALUE_STORE_FEATURES_KEY)
  } else if (platform === 'web') {
    contextPromises.push(getHostLocaleDetailsWeb().catch(() => ({})))
  }
  const contextResults = await Promise.all(contextPromises)

  const userDetails = contextResults[0]
  const deviceInfo = contextResults[1]

  getValueFromValueStore(VALUE_STORE_DEVICEINFO_UUID).catch(() => null),
    getValueFromValueStore(VALUE_STORE_FEATURES_KEY).catch(() => null)

  const hostLocaleObj = {
    locale: deviceInfo?.locale?.languageTag,
    language: deviceInfo?.locale?.language,
    appCountry: deviceInfo?.locale?.country
  }
  hostLocaleDetails = contextResults[2] || hostLocaleObj

  console.log(`hostLocale: ${JSON.stringify(hostLocaleDetails)}`)
  console.log(`appinstanceid:${appIDFromAnalytics}`)
  console.log(`featurelist:${featureList}`)

  setMobileHost([ANDROID, IOS].includes(platform))
  setUserInfo(userDetails)
  setDeviceInfo({
    ...deviceInfo,
    uuid: appIDFromAnalytics ? appIDFromAnalytics : deviceInfo.uuid,
    ...hostLocaleDetails
  })
  setFeatureList(featureList ? JSON.parse(featureList) : {})
}

export const useAppContext = (sectionType) => {
  const [isAppContextFetched, setIsAppContextFetched] = useState(false)

  useEffect(() => {
    const onLoad = async () => {
      await setInitialContext()
      setIsAppContextFetched(true)
    }
    if (sectionType === HPX_INTERSTITIAL_TYPE) {
      // If Interstitial screen, context cannot be determined from app as custom plugins cannot be added to service router.
      // So app context is sent to interstitial screen through query params
      const searchParams = new URLSearchParams(window.location.search)
      const screenContext = {}
      Array.from(searchParams).forEach((s) => {
        screenContext[s[0]] = s[1]
      })
      const platform = screenContext['property']?.split('-')[1]
      setDeviceInfo({ platform: platform, ...screenContext })
      setIsAppContextFetched(true)
    } else {
      onLoad()
    }
  }, [sectionType])

  return {
    isAppContextFetched
  }
}
