class PubSub {
  constructor() {
    this.events = {}
    this.unsubscribeEvents = {}
  }

  subscribe(eventName, func) {
    this.events[eventName] = [func]
  }

  unsubscribe(eventName) {
    if (this.events[eventName]) {
      this.events[eventName] = null
    }
    const funcs = this.unsubscribeEvents[eventName]
    if (Array.isArray(funcs)) {
      funcs.forEach((func) => func())
    }
  }

  runOnUnsubscribe(eventName, func) {
    this.unsubscribeEvents[eventName] = [func]
  }

  publish(eventName, ...args) {
    const funcs = this.events[eventName]
    if (Array.isArray(funcs)) {
      funcs.forEach((func) => {
        func(...args)
      })
    }
  }
}

const pubSub = new PubSub()

export default pubSub
